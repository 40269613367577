import React, { useState } from 'react';
import { postApi } from "../../../configuration/Apis";
import $ from 'jquery'
import Rose from '../../../Assets/images/Group 1092.png';
import Bouquet from '../../../Assets/images/Group.png';
import Chocolates from '../../../Assets/images/image 8.png';
import Ring from '../../../Assets/images/Group (1).png';
import './chat_window.css';
import { useNavigate } from 'react-router-dom';
import { FRONTEND_NAME } from '../../../configuration/constent'
// import Tost from '../../newComponents/tost';
import API_urls from '../../../configuration/API_urls';
import ModalPopup from './Confirmation';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';

import {openTostAlert} from '../../../redux/Actions/modal_action';

const Giftpopup = ({ title, props, AgentId, show, Handleclose }) => {
    const { t } = props;

    const giftsItem = [
        {
            id: '09847213hfgt9834',
            title: 'Rose Flower',
            img: Rose,
            price: 10
        },
        {
            id: '09847213hfgt76547',
            title: 'Bouquet',
            img: Bouquet,
            price: 50
        },
        {
            id: '09847213hfg098798',
            title: 'Chocolates',
            img: Chocolates,
            price: 30
        },
        {
            id: '09847213hfg098568',
            title: 'Ring',
            img: Ring,
            price: 100
        }
    ]

    return (
        <>
            <ModalPopup Isopen={show}
                handleClose={Handleclose}
                title={title}
                modalBody={<GiftList gifts={giftsItem} agentId={AgentId} />}
            />
        </>
    )
}

export default Giftpopup;

const GiftItem = ({ item, onClick, isActive }) => {
    console.log(isActive);
    return (
        <div className={isActive === item.id ? 'gift_item giftActive' : "gift_item"} key={item.id} onClick={() => onClick(item.id, item.price)}>
            <img src={item.img} alt={item.title} width={"60px"} height={'60px'} />
            <div className='w-100'>
                <p className='mt-2'>{item.title}</p>
                <p className='mt-1'><strong>₹ {item.price} </strong></p>
            </div>
        </div>
    );
};


const GiftList = ({ gifts, agentId }) => {
    const [activeItemId, setActiveItemId] = useState(null);
    const history = useNavigate();
    const [price, setPrice] = useState(0);
    const dispatch = useDispatch();
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';

    const handleItemClick = (id, price) => {
        setActiveItemId(id);
        setPrice(price);
    };

    console.log(activeItemId);
    const reviewSubmit = () => {
        if (price !== 0) {
            let url = API_urls.redeem_balance;
            let body = {
                wallet_addr: userProfile.id,
                country: "IN",
                units: price,
                service_type: "Gift",
                to_id: agentId
            };
            const header = {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive"
            }
            return postApi(url, header, body).then((res) => {
                console.log(res);
                dispatch(openTostAlert('Gift has been sent successfully', 'success'))
            }).catch(err => {
                console.log(err);
            })
        } else dispatch(openTostAlert('Please select one gift card ', 'warning'))
    };

    return (
        <>
            <div className={gifts.length > 4 ? 'gift_container d-flex flex-wrap align-content-center justify-content-start' : 'gift_container d-flex flex-wrap align-content-center justify-content-around'}>
                {gifts.map((item) => (
                    <GiftItem
                        key={item.id}
                        item={item}
                        onClick={handleItemClick}
                        isActive={activeItemId}
                    />
                ))}
            </div>
            <div className='bth_container_gift'>
                <Button variant="contained" className='giftRechargebtn' onClick={() => history('/recharge')}>{('Recharge')}</Button>
                <Button variant="outlined" className='giftSubmitbtn' onClick={reviewSubmit}>{('Submit')}</Button>
            </div>
            <div className="text-center">
                <p className='m-0 fs-12'>Creators can get paid based on gift received in live. <a href=''>Learn more</a></p>
            </div>
        </>
    );
};