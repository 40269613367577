import {ASTRO_API_URL} from './constent';
export default {
    Fatch_user : ASTRO_API_URL + 'fetch_user',
    Login_with_otp: ASTRO_API_URL + 'get_otp',
    verify_otp: ASTRO_API_URL + 'verify_otp',
    wallet_balance : ASTRO_API_URL + 'wallet_balance',
    Get_all_online_Agent : ASTRO_API_URL + 'online',
    Wallet_transactions : ASTRO_API_URL + 'transactions',
    pushIvrObd:'https://adaami.com/FlexPlatformFriendsChat/pushIvrObd',
    AllAgents:ASTRO_API_URL +'astrologers',
    inboxes :ASTRO_API_URL + 'inboxes',
    chat_history:ASTRO_API_URL+'chat_history',
    create_order:ASTRO_API_URL+'create_order',
    redeem_balance:ASTRO_API_URL+'redeem_balance',
    mark_pandit_online : ASTRO_API_URL + 'mark_pandit_online',
    update_profile : ASTRO_API_URL + 'edit_user/<id>',
  };