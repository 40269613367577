import React, { useEffect, useState } from "react";
import './pageHeader.css';
import Preferences from '../../Assets/images/Group 1230.png'
import CallHistory from '../../Assets/images/Group 1231.png'
import HelpCenter from '../../Assets/images/Group 1232.png'
import Share from '../../Assets/images/Group 1233.png'
import Wallet from '../../Assets/images/Group 1234.png'
import ChatHistory from '../../Assets/images/Group 1235.png'
import Review from '../../Assets/images/Group 1236.png'
import Logout from '../../Assets/images/Group 1237.png'
import { useNavigate } from 'react-router-dom'
import * as Constant from '../../configuration/constent';
import faqs from '../../Assets/images/Group 1397.png'
import Support from '../../Assets/images/Group 1398.png'
import Privacy from '../../Assets/images/Group 1399.png'
import TandC from '../../Assets/images/Group 1400.png'
import Refund from '../../Assets/images/Group 1401.png'
import Deactivate from '../../Assets/images/Group 1404.png'
import Delete from '../../Assets/images/Group 1402.png';
// import Support1 from "../Support";
import Facebook from '../../Assets/images/facebook.png';
import Insta from '../../Assets/images/insta.png';
import Snap from '../../Assets/images/snap.png';
import Moze from '../../Assets/images/Moze.png';
import Jhos from '../../Assets/images/Josh.png';
import { useDispatch } from 'react-redux'
import { openModal } from "../../redux/Actions/modal_action";
import Modals from "../Login/modal";
import { wallet_balanceAction } from "../../redux/Actions/wallet_Balance_Action";
import Avatar from '@mui/material/Avatar';
import BackIcon from '../../Assets/images/Group 1407.png'
import Dropdown from "./Dropdown";
import Wallet_Balance from "./balance";

const DropdownData = [
    { title: 'FAQs', url: '/faqs', img: faqs },
    { title: 'Support', url: '/support', img: Support },
    { title: 'Privacy Policy', url: '/privacy-policy', img: Privacy },
    { title: 'Terms & Conditions', url: '/terms-&-conditions', img: TandC },
    { title: 'Refund & cancellation', url: '/refund-&-cancellation-Policy', img: Refund },
    { title: 'Deactivate Account', url: '/support', img: Deactivate },
    { title: 'Delet Account', url: '/support', img: Delete },
]

const shareWith = [
    { title: 'Facebook', url: 'https://www.facebook.com/', img: Facebook },
    { title: 'Instagram', url: 'https://www.instagram.com/', img: Insta },
    { title: 'Snap', url: 'https://snap.com/', img: Snap },
    { title: 'Moj', url: 'https://mojapp.in/', img: Moze },
    { title: 'Josh', url: 'https://share.myjosh.in/', img: Jhos },
]

const PageHeader = ({ title }) => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    const Balance = localStorage.getItem('Balance');
    const isUserLoggedIn = localStorage.getItem('isUserLoggedIn') ?? false;


    useEffect(() => {
        if (isUserLoggedIn) {
            dispatch(wallet_balanceAction(userProfile.id))
        }
    }, []);

    const rediractRoute = (route) => {
        history(Constant.FRONTEND_NAME + route)
    }

    

    return (<>
        <Modals />
        <div className="pageheader_container">
            <div className="w-100 mob_480">
                <div className="mob_responsive_container">
                    <div className="d-flex column-gap-3 align-items-center">
                        <Avatar alt="Remy Sharp" src={BackIcon} width={'40px'} onClick={() => history(-1)} />
                        <span><strong className="text-white">{title}</strong></span>
                    </div>
                </div>
            </div>
            <div className="desk_top">
                <div className="pageheader_top1">
                    <div className="container">
                        <Wallet_Balance />
                    </div>
                </div>

                <div className="pageheader_top2">
                    <div className="container">
                        <div className="menu_container d-flex">
                            <div className="AgentHead_container">
                                <p className="m-0"> {title} </p>
                            </div>
                            <ul className=" d-flex column-gap-3 flex-wrap" id="navMenu">
                                <li onClick={() => {
                                    if (!isUserLoggedIn) {
                                        dispatch(openModal());
                                    } else rediractRoute('/preferences')
                                }
                                }><img src={Preferences} alt="Preferences" /> <span className="navLink">Preferences</span></li>
                                <li onClick={() => {
                                    if (!isUserLoggedIn) {
                                        dispatch(openModal());
                                    } else rediractRoute('/call-history')
                                }}><img src={CallHistory} alt="Call History" /> <span className="navLink">Call History</span></li>
                                <li className="Help_container">
                                    <img src={HelpCenter} alt="Help Center" />
                                    <Dropdown title="Help Center" DropdownItem={DropdownData} />
                                </li>
                                <li className="Help_container"><img src={Share} alt="Share" />
                                    <Dropdown title="Share" DropdownItem={shareWith} />
                                </li>
                                <li onClick={() => {
                                    if (!isUserLoggedIn) {
                                        dispatch(openModal());
                                    } else rediractRoute('/wallet-transactions')
                                }}>
                                    <img src={Wallet} alt="Wallet Transactions" /> <span className="navLink">Wallet Transactions </span>
                                </li>
                                <li onClick={() => {
                                    if (!isUserLoggedIn) {
                                        dispatch(openModal());
                                    } else rediractRoute('/chat-history')
                                }}><img src={ChatHistory} alt="Chat History" /> <span className="navLink">Chat History</span></li>

                                <li onClick={() => window.open('https://play.google.com/store/games?device=windows&pli=1', '_blank')}><img src={Review} alt="Review Us" /> <span className="navLink">Review Us</span></li>

                                <li onClick={() => {
                                    if (!isUserLoggedIn) {
                                        dispatch(openModal());
                                    } else {
                                        localStorage.clear();
                                        window.location.reload();
                                    }
                                }}><img src={Logout} alt="Logout" /> <span className="navLink"> {isUserLoggedIn ? 'Logout' : 'Login'} </span></li>
                            </ul>
                            {/* <Support1 /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default PageHeader;