import React, { useEffect, useState } from "react";
import Header from "../Components/header";
import PageHeader from "../Components/Header/pageHeader";
import Footer from "../Components/Footer/Footer";
import { useDispatch, useSelector } from 'react-redux'
import { CallHistoryAction, ChatHistoryAction, GetChatMsgAction } from "../redux/Actions/GetAllonlineAgentsAction";
import Grid from '@mui/material/Grid';
import SkeletonChildren from '../Components/card/SkeletonCard';
import AgentCard from '../Components/card/AgentCard';
import { IslodaingTrueAction } from '../redux/Actions/GetAllonlineAgentsAction';
import { InitiateCallAction } from '../redux/Actions/RequestCallAction';
import { openTostAlert } from "../redux/Actions/modal_action";
import { useNavigate } from 'react-router-dom';
import ChatMsg from "../Components/modal/chat_msg";

export default function AgentChatHistory() {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [show, setShow] = useState(false);
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';
    const ChatHistory = useSelector(state => state.GetChatHistoryReducer.chatHistory) ?? [];
    const Chatmsg = useSelector(state => state.GetChatMsgReducer.chatMsg) ?? [];
    const userBalance = useSelector(state => state.walletBalanceReducer.Balance) ?? [];
    const IsUserLogdin = localStorage.getItem('isUserLoggedIn') ?? false;

    console.log(ChatHistory);
    useEffect(() => {
        dispatch(ChatHistoryAction(userProfile.id))
    }, [dispatch]);

    const initiateRequest = (data) => {
        if (IsUserLogdin) {
            if (userBalance < data.profile.rate.CHAT_IN * 5) {
                dispatch(openTostAlert("Minimum balance 5 mins chat (" +
                    '₹' + data.profile.rate.CHAT_IN * 5 + " for 5 mins) is required to chat with astrologer.", 'warning'))
            } else {
                history('/chat');
            };
        }
    }

    const getChatHistory = (inboxid) => {
        console.log(inboxid);
        setShow(true);
        dispatch(GetChatMsgAction(inboxid))
      };


    return <>
        <div className="desk_top">
            <Header />
        </div>
        <PageHeader title={'Chat History'} />
        <div className='container'>
            <div className="agents_card_container mb-5">
                <div className="slider-container mt-5 p-2">

                    {
                        Object.keys(ChatHistory).length === 0 ? <Grid container spacing={3}>
                            {Array.from({ length: 6 }, (_, index) => <SkeletonChildren key={index} />)}</Grid>

                            : <div className='d-flex w-100 flex-wrap column-gap-3 row-gap-3 justify-content-start'>
                                {
                                    ChatHistory.map(item => {
                                        // console.log(item);
                                        return <AgentCard data={item}
                                            id={item.user.id}
                                            image={item.user.avatar}
                                            name={item.user.name}
                                            inboxid={item.inbox_addr}
                                            age={'25'}
                                            eng={"Hindi,English"}
                                            description={'Talks about: Cooking, Dating, Friendship, Make-Up, Acting'}
                                            chathistroy={true}
                                            IsCallHistory={false}
                                            IsCall={false}
                                            IsChat={true}
                                            isLine2={true}
                                            IslodaingTrue={''}
                                            IsFavourite={false}
                                            // AddToFavourite={AddToFavourite}
                                            CallinitiateRequest={() => initiateRequest(item)}
                                            getChatHistory={getChatHistory}
                                        />
                                    }
                                    )
                                }
                            </div>
                    }

                </div>

            </div>
        </div>
        <ChatMsg data={Chatmsg} show={show} handleClose={() => setShow(false)} />
        <div className="mt-5 mob_480">&nbsp;</div>
        <div className="desk_top">
            <Footer />
        </div>
    </>
}