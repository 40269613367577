import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';

export const Countdown = ({OtpsendHandle}) => {
    const count = useSelector(state => state.loginReducer.count);
    const [seconds, setSeconds] = useState(count);

    useEffect(() => {
        // Start countdown when component mounts
        const countdownInterval = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 0) {
                    // Reset countdown when it reaches 0
                    clearInterval(countdownInterval);
                    return 0;
                } else {
                    // Decrement seconds
                    return prevSeconds - 1;
                }
            });
        }, 1000);

        // Clean up interval when component unmounts
        return () => clearInterval(countdownInterval);
    }, [count]); // Dependency on 'count' ensures effect is re-run when 'count' changes

    return <div className='form-group d-flex align-items-center justify-content-between mt-3'>
    <div className='OtpTime'>
        {seconds === 0 ? (
            <span>Time's up!</span>
        ) : (
            <span>Remaining time: {Math.floor(seconds / 60)}:{seconds % 60 < 10 ? '0' : ''}{seconds % 60} minutes</span>
        )}

    </div>
    <div className='OtpReSend'>
        <Button onClick={OtpsendHandle} disabled={seconds > 0 ? true : false}> Send OTP </Button>
    </div>
</div>;
};
