import React from 'react';
import Header from '../Components/header';
import Footer from '../Components/Footer/Footer';
import PageHeader from '../Components/Header/pageHeader';
import { useSelector, useDispatch } from 'react-redux';
import SkeletonChildren from '../Components/card/SkeletonCard';
import AgentCard from '../Components/card/AgentCard';
import Grid from '@mui/material/Grid';
import Category from '../Components/Category/category';
import { openModal, openTostAlert } from '../redux/Actions/modal_action';
import { useNavigate } from 'react-router-dom';


export default function ChatNow() {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [favourite, setFavourite] = React.useState(JSON.parse(localStorage.getItem('favourite')));
    const [filteredData, setFilteredData] = React.useState([]);
    const [value, setValue] = React.useState('all');
    const Agents = useSelector(state => state.GetAllAgentReducer.AllAgents) ?? [];
    // const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';
    const isUserLoggedIn = localStorage.getItem('isUserLoggedIn') ?? false;
    const walletBalance = useSelector(state => (state.walletBalanceReducer.Balance ? state.walletBalanceReducer.Balance : ''));


    const initiateRequest = (data) => {
        if (isUserLoggedIn) {
            if (walletBalance < data.pandit.profile.rate.CHAT_IN * 5) {
                dispatch(openTostAlert("Minimum balance 5 mins chat (" +
                    '₹' + data.pandit.profile.rate.CHAT_IN * 5 + " for 5 mins) is required to chat with Agents.", 'warning'))
            } else {
                history("/chat", {
                    state: {
                        typeOfService: 'chat',
                        panditMsisdn: data.pandit.user.id,
                        orderDetail: data,
                        user_Id: localStorage.getItem('user_Id')
                    },
                });
            };
        } else dispatch(openModal());
    }

    const filterByCategory = (event, expertise) => {
        setValue(expertise);
        if (expertise !== 'all') {
            const filteredByExpertise = Agents.filter(item => {
                if (item.pandit.profile && item.pandit.profile.expertise) {
                    return item.pandit.profile.expertise.includes(expertise);
                }
                return false;
            });
            setFilteredData(filteredByExpertise);
        } else setFilteredData(Agents);

    }

    const AddToFavourite =(data)=>{
        const itemIsExist = favourite.some(item => item.pandit.user.id === data.pandit.user.id);
        if(!itemIsExist){
          setFavourite([...favourite, data]); 
          localStorage.setItem('favourite',JSON.stringify([...favourite, data]));
          dispatch(openTostAlert('Agent successfully added to the Favourite!','success'));
        } else dispatch(openTostAlert('Agent already exists in the Favourite!','warning'));
    }

    return <>
        <div className='desk_top'>
            <Header />
        </div>

        <PageHeader title={'Chat'} />
        <div className='container'>
            <div className="agents_card_container mb-5">
                <div className="slider-container chatnow_container p-2">
                    <div className='mb-4'>
                        <Category value={value} categoryHandle={filterByCategory} />
                    </div>
                    {
                        Object.keys(Agents).length === 0 ? <Grid container spacing={3}>
                            {Array.from({ length: 3 }, (_, index) => <SkeletonChildren id={index} />)} </Grid>
                            : <div className='d-flex w-100 flex-wrap column-gap-3 row-gap-3 justify-content-start'>
                                {
                                    filteredData.length !== 0 ? filteredData.map(item => <AgentCard data={item}
                                        id={item.pandit.user.id}
                                        image={item.pandit.user.avatar}
                                        name={item.pandit.user.name}
                                        age={'25'}
                                        eng={"Hindi,English"}
                                        description={'Talks about: Cooking, Dating, Friendship, Make-Up, Acting'}
                                        IsCall={false}
                                        IsChat={true}
                                        isLine2={true}
                                        // IslodaingTrue={IslodaingTrue}
                                        IsFavourite={true}
                                        AddToFavourite={AddToFavourite}
                                        ChatinitiateRequest={initiateRequest}
                                    />

                                    ) : Agents.map(item => <AgentCard data={item}
                                        id={item.pandit.user.id}
                                        image={item.pandit.user.avatar}
                                        name={item.pandit.user.name}
                                        age={'25'}
                                        eng={"Hindi,English"}
                                        description={'Talks about: Cooking, Dating, Friendship, Make-Up, Acting'}
                                        IsCall={false}
                                        IsChat={true}
                                        isLine2={true}
                                        // IslodaingTrue={IslodaingTrue}
                                        IsFavourite={true}
                                        AddToFavourite={AddToFavourite}
                                        ChatinitiateRequest={initiateRequest}
                                    />)
                                }
                            </div>
                    }

                </div>

            </div>
        </div>
        <div className="mt-5 mob_480">&nbsp;</div>
        <div className='desk_top'>
            <Footer />
        </div>
    </>
}