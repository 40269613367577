import React, { useState, useRef, useEffect } from 'react';
import Buttons from '../Button';
import './Otp_verify.css';
import { Countdown } from './OtpTime';


const OTPInput = ({ length, onComplete, phone, VerifyOTPHandle, OtpsendHandle }) => {
    const [otp, setOtp] = useState(Array(length).fill(''));
    const inputRefs = useRef([]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, length);
    }, [length]);

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (isNaN(value)) return;

        const newOtp = [...otp];
        newOtp[index] = value.substr(-1);
        setOtp(newOtp);

        // Focus next input
        if (value && index < length - 1) {
            inputRefs.current[index + 1].focus();
        }

        // Call onComplete if all inputs are filled
        if (!newOtp.includes('') && typeof onComplete === 'function') {
            onComplete(newOtp.join(''));
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && !otp[index]) {
            // Move focus to previous input on Backspace
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <div className='Opt_container text-center'>
            <div className='otp_head mt-3 mb-4'> OTP sent to {phone} </div>
            <form>
                {Array.from({ length }).map((_, index) => (
                    <input
                        key={index}
                        type="text"
                        value={otp[index] || ''}
                        onChange={e => handleChange(e, index)}
                        onKeyDown={e => handleKeyDown(e, index)}
                        maxLength="1"
                        ref={el => inputRefs.current[index] = el}
                        className='Otp_verify_input'
                    />
                ))}

                <div className='form-group text-center mt-4'>
                    <Buttons title={'Verify OTP'} size={'lg'} className={'Btn_bg login_btn'} onclick={VerifyOTPHandle} />
                </div>
                <Countdown OtpsendHandle={OtpsendHandle} />
            </form>
        </div>
    );
};

export default OTPInput;
