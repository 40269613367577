import * as React from 'react';
import Alert from '@mui/material/Alert';
import './alert.css';
import {useDispatch,useSelector} from 'react-redux'
import {closeTostAlert} from '../../redux/Actions/modal_action'


const TostAlert = ({color,title})  => {
  const dispatch = useDispatch();
  const isTostOpen = useSelector(state => state.TostReducer.isTostOpen);
  // console.log(isTostOpen);
  
  return <> {isTostOpen ? <Alert severity={color} className='Alet_container' onClose={()=> dispatch (closeTostAlert())}> {title}</Alert> :''}</>;
}

export default TostAlert;