import { WALLET_RECHARGE, WALLET_RECHARGE_FAILURE } from "../constant"

const initialState111 = {
    amount:null
}
export const walletRechargeReducer = (state = initialState111, action) => {
    switch(action.type){
        case WALLET_RECHARGE:
            return {...state, amount:action.payload};
        case WALLET_RECHARGE_FAILURE:
            return {...state, amount:null}
        default:
            return state;
    }
}