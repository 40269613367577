import React from 'react';
import './wt.css';

const TableItem = ({icon, serviceTitle, date, amount,Isadd}) => {
    return <div className="table_item_container">
    <div className="table_icon">
        <div className="service_icon">
            <img src={icon} alt="services_type" width={'55px'} />
        </div>
        <div className="service_type">
            <h6 className="m-0"><strong>{serviceTitle}</strong></h6>
            <caption>{date}</caption>
        </div>
    </div>
    <div className="table_content">
        <div className="services_deduction">
        <h6 className="m-0"><strong>{`${Isadd ? "+ ":"- "} ₹ ${amount}`}</strong></h6>
        </div>
    </div>
</div>
}

export default TableItem;