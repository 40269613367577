import axios from "axios";

export const postApi = (url, headers = {}, requestBody = {}) => {
  return axios.post(url, requestBody, { headers });
};

export const getApi = (url, headers) => {
  return axios.get(url, { data: null }, { headers });
};

export function getCommonHeaders() {
  var headers = {};
  headers = {
    "Content-Type": "application/json",
    "Accept": "*/*",
    // "Accept-Encoding": "gzip, deflate, br",
    // "Connection": "keep-alive"
  }
}