import React from 'react';
import {Routes, Route} from 'react-router-dom'
import Home from './Screens/Home';
import TalkNow from './Screens/talknow';
import BottomNavigations from './Components/Header/BottomHeader';
import ChatNow from './Screens/Chatnow';
import PrivacyPolicy from './Screens/privacyPolicy';
import Terms from './Screens/TC';
import RefundPolicy from './Screens/refundPolicy';
import Disclaimer from './Screens/disclaimer';
import FAQs from './Screens/FAQs';
import Support from './Screens/Support';
import WalletTransactions from './Screens/WalletTransactions';
import { useSelector, useDispatch } from 'react-redux';
import TostAlerts from './Components/Alert/alert';
import CallHistroy from './Screens/Callhistroy';
import ChatHistroy from './Screens/ChatHistory';
import WalletRecharge from './Screens/Recharge';
import Payment from './Screens/payment';
import Socketchat from './Screens/socketchat';
import Preferences from './Screens/Preferences';
import UserProfile from './Screens/UserProfile';
import Favourite from './Screens/Favourite';
import TostCallAlert from './Components/Alert/callAlert';


export default function Router(){
    const TostMsg = useSelector(state => state.TostReducer.msg);
    const TostType = useSelector(state => state.TostReducer.TostType);
    const TostCallMsg = useSelector(state => state.TostCallReducer.msg);
    
    return<>
        <TostAlerts title={TostMsg ?? ''} color={TostType ?? ''} />
        <TostCallAlert title={TostCallMsg} />
        <BottomNavigations />
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/talkNow' element={<TalkNow />} />
            <Route path='/chatnow' element={<ChatNow />} />
            <Route path='/call-history' element={<CallHistroy />} />
            <Route path='/chat-history' element={<ChatHistroy />} />
            <Route path='/favourite' element={<Favourite />} />
            <Route path='/chat' element={<Socketchat />} />
            <Route path='/recharge' element={<WalletRecharge />} />
            <Route path='/profile' element={<UserProfile />} />
            <Route path='/preferences' element={<Preferences />} />
            <Route path='/payment' element={<Payment />} />
            <Route path='/faqs' element={<FAQs />} />
            <Route path='/support' element={<Support />} />
            <Route path='/wallet-transactions' element={<WalletTransactions />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-&-conditions' element={<Terms />} />
            <Route path='/refund-&-cancellation-Policy' element={<RefundPolicy />} />
            <Route path='/disclaimer' element={<Disclaimer />} />
        </Routes>
    </>
}