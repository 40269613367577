import React from "react";
import './heading.css';

const Heading =({title,tagline}) => {
    return <>
        <div className="Heading_container">
            <div className="head_inner">
                <h3>{title}</h3>
                <p className="m-0">{tagline}</p>
            </div>
        </div>
    </>
}
export default Heading;