import React from 'react';
import './App.css';
import Route from './Route';
import { useDispatch } from 'react-redux'
import {GetAllAgentAction } from './redux/Actions/GetAllonlineAgentsAction';
import { getAllWalletTransactionAction } from './redux/Actions/walletTransactionAction';


function App() {
  const dispatch = useDispatch();
  const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';
  const IsUserLogdin = localStorage.getItem('isUserLoggedIn') ?? false;
  React.useEffect(() => {
    if(IsUserLogdin) {
      dispatch(getAllWalletTransactionAction(userProfile.wallet_addr));
    }
    dispatch(GetAllAgentAction());
}, [dispatch]);

  return (<div className='App'><Route /></div>);
}
 
export default App;
