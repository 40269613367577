import React from "react";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Button from '@mui/material/Button';
const RechargeCard = ({ pricetag, OnclickHandle }) => {

  return <Button sx={{width:'100%'}} onClick={() => OnclickHandle(pricetag)} variant="outlined" startIcon={<CurrencyRupeeIcon />}>
      {pricetag}
    </Button>
}

export default RechargeCard;
