import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import Avatar from '@mui/material/Avatar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

function Links({ isOpen, onClose,IsShare, data }) {
    const navigate = useNavigate();

    if (isOpen) {
        return (
            <div className=''>
                <Alert variant="light"onClose={onClose} dismissible>
                    <div className='navBar_122'>
                        <ul className='list-unstyled p-0'>
                            {
                                data.map((item, index) => {
                                    return <li className='mobNavLink' key={index} onClick={() => { IsShare ?  window.open(item.url,'_blank') : navigate(item.url)}}>
                                    <span className='d-flex column-gap-3 align-items-center'>
                                        <Avatar alt="FAQs" src={item.icon} sx={{ width: 30, height: 30 }} />
                                        <span> {item.name} </span>
                                    </span>
                                   
                                    <ArrowForwardIosIcon fontSize="inherit" />
                                </li>
                                })
                            }
                            
                            

                        </ul>
                    </div>
                </Alert>
            </div>
        );
    }

}

export default Links;