import * as Constant from '../constant'

export const openModal = () => {
    return ({
        type: Constant.OPEN_MODAL
      })
};
  
  export const closeModal = () => ({
    type: Constant.CLOSE_MODAL
  });


export const openTostAlert = (msg,Ttype) => {
    return ({
        type: Constant.OPEN_TOST_ALERT,
        payload:{msg, Ttype}
      })
};

export const closeTostAlert = () => {
    return ({
        type: Constant.CLOSE_TOST_ALERT
      })
};


export const openTostCallAlert = (msg,Ttype) => {
  return ({
      type: Constant.OPEN_TOST_CALL_ALERT,
      payload:{msg, Ttype}
    })
};

export const closeTostCallAlert = () => {
  return ({
      type: Constant.CLOSE_TOST_CALL_ALERT
    })
};

export const Callinitiated = (boolean) => {
  return ({ type: Constant.CALL_INITIATED,payload:boolean})
}
export const Loading = (loading) => {
  return ({ type: loading})
};