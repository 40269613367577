import React from 'react';
import Header from '../Components/header';
import Footer from '../Components/Footer/Footer';
import PageHeader from '../Components/Header/pageHeader';
import { useSelector, useDispatch } from 'react-redux';
import SkeletonChildren from '../Components/card/SkeletonCard';
import AgentCard from '../Components/card/AgentCard';
import Grid from '@mui/material/Grid';
import {IslodaingTrueAction,addToCart } from '../redux/Actions/GetAllonlineAgentsAction';
import { InitiateCallAction } from '../redux/Actions/RequestCallAction';
import Category from '../Components/Category/category';
import { openModal } from '../redux/Actions/modal_action';



export default function TalkNow() {
    const dispatch = useDispatch();
    const [filteredData, setFilteredData] = React.useState([]);
    const [value, setValue] = React.useState('all');    
    const isDisabled = useSelector(state => state.IsCallInitiatedReducer.isDisabled);
    const Agents = useSelector(state => state.GetAllAgentReducer.AllAgents) ?? [];
    const IsLoading = useSelector(state => state.IslodaingTrueReducer.IsLoading) ?? [];
    const userBalance = useSelector(state => state.walletBalanceReducer.Balance) ?? [];
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';
    const IsUserLogdin = localStorage.getItem('isUserLoggedIn') ?? false;
    const IsFreeSession = localStorage.getItem('IsFreeSession') ?? false;
    // const [favourite, setFavourite] = React.useState(JSON.parse(localStorage.getItem('favourite')));

    // console.log(isDisabled);
    const initiateCallRequest = (data) => { 
        dispatch(InitiateCallAction(data, userBalance, userProfile.phones[0],IsFreeSession,isDisabled)); 
    }

    const filterByCategory = (event, expertise) => {
        setValue(expertise);
        if (expertise !== 'all') {
            const filteredByExpertise = Agents.filter(item => {
                if (item.pandit.profile && item.pandit.profile.expertise) {
                    return item.pandit.profile.expertise.includes(expertise);
                }
                return false;
            });
            setFilteredData(filteredByExpertise);
        } else setFilteredData(Agents);

    }

    const AddToFavourite =(data)=>{
        dispatch(addToCart(data))
        // const itemIsExist = favourite.some(item => item.pandit.user.id === data.pandit.user.id);
        // if(!itemIsExist){
        //   setFavourite([...favourite, data]);
        //   console.log(favourite);
        //   localStorage.setItem('favourite',JSON.stringify([...favourite, data]));
        //  dispatch(openTostAlert('Agent successfully added to the Favourite!','success'));
        // } else dispatch(openTostAlert('Agent already exists in the Favourite!','warning'));
    }

// console.log(Agents);
    return <>
        <div className='desk_top'>
            <Header />
        </div>
        <PageHeader title={'Call'} />
        <div className='container'>
            <div className="agents_card_container mb-5">
                <div className="slider-container chatnow_container p-2">
                    <div className='mb-4'>
                        <Category value={value} categoryHandle={filterByCategory} />
                    </div>

                    {Object.keys(Agents).length === 0 ? <Grid container spacing={3}>
                            {Array.from({ length: 4 }, (_, index) => <SkeletonChildren id={index} />)}
                        </Grid>: <div className='d-flex w-100 flex-wrap column-gap-3 row-gap-3 justify-content-start'>
                            {filteredData.length !== 0 ? filteredData.map(item => {
                                        if(item.current_activity == null){
                                            // console.log(item.current_activity);
                                            return <AgentCard data={item}
                                            id={item.pandit.user.id}
                                            image={item.pandit.user.avatar}
                                            name={item.pandit.user.name}
                                            age={'25'}
                                            eng={"Hindi,English"}
                                            description={'Talks about: Cooking, Dating, Friendship, Make-Up, Acting'}
                                            IsCall={true}
                                            isLine2={true}
                                            IslodaingTrue={IsLoading == item.pandit.user.id}
                                            IsFavourite={false}
                                            AddToFavourite={AddToFavourite}
                                            CallinitiateRequest={() => {
                                                if(IsUserLogdin){
                                                    dispatch(IslodaingTrueAction(item.pandit.user.id));
                                                    initiateCallRequest(item);
                                                }else {
                                                    dispatch(openModal())
                                                    dispatch(IslodaingTrueAction(''));
                                                };
                                            }}
                                        />
                                         }}) : Agents.map(item => {
                                            // console.log(item.pandit.user.avatar);
                                            if(item.current_activity == null){
                                                return <AgentCard data={item}
                                                id={item.pandit.user.id}
                                                image={item.pandit.user.avatar}
                                                name={item.pandit.user.name}
                                                age={'25'}
                                                eng={"Hindi,English"}
                                                description={'Talks about: Cooking, Dating, Friendship, Make-Up, Acting'}
                                                IsCall={true}
                                                isLine2={true}
                                                IslodaingTrue={IsLoading == item.pandit.user.id}
                                                IsFavourite={true}
                                                AddToFavourite={AddToFavourite}
                                                CallinitiateRequest={() => {
                                                    if(IsUserLogdin){
                                                        dispatch(IslodaingTrueAction(item.pandit.user.id));
                                                        initiateCallRequest(item);
                                                    }else {
                                                        dispatch(openModal())
                                                        dispatch(IslodaingTrueAction(''));
                                                    };
                                                   
                                                }}
                                            />
                                        }})
                                }
                            </div>
                    }

                </div>

            </div>
        </div>
        <div className="mt-5 mob_480">&nbsp;</div>
        <div className='desk_top'>
            <Footer />
        </div>
    </>
}