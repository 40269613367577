import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Home from '../../Assets/images/Home.png'
import Call from '../../Assets/images/Call.png'
import Chat from '../../Assets/images/chat.png'
import Fav from '../../Assets/images/Fev.png'
import {useNavigate} from 'react-router-dom';
import './pageHeader.css';

export default function BottomNavigations({defaultValue}) {
    const history = useNavigate();
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event,newValue) => {
    setValue(newValue);
    // console.log(newValue);
  };

  return (
    <BottomNavigation sx={{ width: '100%' }} className='bottom_header_container mob_480' value={value} onChange={handleChange}>
      <BottomNavigationAction
        onClick={()=> history('/')}
        label="Home"
        value="home"
        className='btn_container'
        icon={<img src={Home} alt="" width={'20px'} />}
      />
      <BottomNavigationAction
        onClick={()=> history('/talknow')}
        label="Call"
        value="call"
        className='btn_container'
        icon={<img src={Call} alt="" width={'20px'} />}
      />
       <BottomNavigationAction
       onClick={()=> history('/chatnow')}
        label="Chat"
        value="chat"
        className='btn_container'
        icon={<img src={Chat} alt="" width={'25px'} />}
      />
       <BottomNavigationAction
       onClick={()=> history('/favourite')}
        label="Favorites"
        value="favorites"
        className='btn_container'
        icon={<img src={Fav} alt="" width={'20px'} />}
      />
    </BottomNavigation>
  );
}
