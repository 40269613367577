export const HTTP1 = "http://localhost:3000";  // for Localhost 
// export const HTTP1 = "http://localhost:3002";  // for Localhost 
// export const HTTP1 = "http://14.99.239.245";  // for UAT 

// export const HTTP = "//";  // for UAT 
export const HTTP = "https://"; // for producation

const CONTENT_PROJECT_NAME = "ContentManager2";
//const SPS_PROJECT_NAME = "SPSBilling";
const PROFILER_PROJECT_NAME = "Profiler";

export const DOMAIN_ADDRESS = "14.99.239.244";


// export const DEV_URL = "localhost:8080"; //for stage
//export const DEV_URL = "digital.mv1.in"; //for stage
export const DEV_URL = "astroking.net:8080";  //for uat
// export const DEV_URL = "myastrotell.com"; //for prod
// export const Armour_mobi="https://armour.mobi:7000/";
export const Armour_mobi="https://astroking.net/7000/";

const FLEX_PLATFORM = "FlexPlatform";

export const PROFILER_IP_ADDRESS = HTTP + DEV_URL + "/" + PROFILER_PROJECT_NAME;
export const CONTENT_IP_ADRESS = HTTP + DEV_URL + "/" + CONTENT_PROJECT_NAME;
export const ASTRO_URL = HTTP + DEV_URL;

// export const FRONTEND_NAME = "/talknow"; //for stage, uat 
export const FRONTEND_NAME = ""; //for prod

//export const CHAT_API_URL = HTTP +"digital.mv1.in:9090/ChatMachine"; // stage
export const CHAT_API_URL = HTTP + "astroking.net:8080/ChatMachine";  //uat
// export const CHAT_API_URL = "ws://localhost:3000/ws"; //localhost


//export const RAZORPAY_API_URL = HTTP +"digital.mv1.in:9090/RazorPayIntegration"; // stage
export const RAZORPAY_API_URL = HTTP + "astroking.net:8080/RazorPayIntegration";  //uat
// export const RAZORPAY_API_URL = HTTP + "localhost:8080/RazorPay";  //local

// export const RAZORPAY_KEY = 'rzp_test_Z05KGs2HYnDIzY' // uat
// export const RAZORPAY_KEY = 'rzp_live_JOksMhu2wnFSqP' // producation
export const RAZORPAY_KEY = 'rzp_live_GsTe9v0aIRVwrp' // producation
// export const RAZORPAY_KEY = 'rzp_live_GsTe9v0alRVwrp' // producation


export const FLEX_PLATFORM_URL = HTTP + DEV_URL + "/" + FLEX_PLATFORM;
export const ASTRO_API_URL = Armour_mobi + 'astroapi/';

export const FirstMessage = "Hey <name> 👋 I stumbled upon your profile and noticed we share some common interests. I'd love to chat and get to know you better!";
export const secondMessage = "";