import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal, openTostAlert } from '../../redux/Actions/modal_action';
import { Fatch_user,loginOtp, verifyOTPAction} from '../../redux/Actions/login_action';
import './modal.css';
import Login from './login';
import OTPInput from './OTP';
import { wallet_balanceAction } from '../../redux/Actions/wallet_Balance_Action';
import TransitionAlerts from '../Alert/alert';


function Modals() {
    const isModalOpen = useSelector(state => state.modalReducer.isModalOpen);
    const UserProfile = useSelector(state => state.FatchUserReducer.userProfile);
    const SentOTP = useSelector(state => state.loginReducer.sentOTP);
    const [Loading, setLoading] = useState(false);
    const TostMsg = useSelector(state => state.TostReducer.msg);
    const TostType = useSelector(state => state.TostReducer.TostType);
    const dispatch = useDispatch();

    const [ phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');


    const handleClose = () => {
        dispatch(closeModal());
        if(otp.length !== 6){
            localStorage.clear();
        }
    };

    const InputOnchangeHandle = (event) => {
        if(!isNaN(event.target.value)){
            setPhone(event.target.value);
        }
    }

    const OnSubmitHandle = (e) => {
        e.preventDefault();
        setLoading(true);
        if(phone !== ''){
            dispatch(Fatch_user(phone));
        }else dispatch(openTostAlert('*Please fill all mandatory fields','warning'))
    }

    React.useEffect(() => {
        OtpsendHandle();
    }, [UserProfile, dispatch]);

    
    
    const OtpsendHandle = () => {
        if(phone !== ''){
            if (UserProfile.id) {
                dispatch(loginOtp(UserProfile.id));
            }
         };        
    }

    const handleOTPComplete = otpValue => {setOtp(otpValue)};

    const VerifyOTPHandle = (e) => {
        e.preventDefault();
        if(otp.length !== 6){
            dispatch(openTostAlert('*Please enter valid OTP','warning'))
        }else {
            dispatch(verifyOTPAction(UserProfile.id, otp));
            dispatch(wallet_balanceAction(UserProfile.id));
        }
    }


    return (
        <>
            <Modal
                show={isModalOpen}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className='login_sign_container'
            >
                <Modal.Header closeButton>
                    <Modal.Title className='title_head'> Continue with Phone </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TransitionAlerts title={TostMsg ?? ''} color={TostType ?? ''} />
                    {
                        !SentOTP ? <Login value={phone} Loading={Loading} InputOnchangeHandle={InputOnchangeHandle} OnSubmitHandle={OnSubmitHandle} />: <OTPInput length={6} phone={phone} onComplete={handleOTPComplete} VerifyOTPHandle={VerifyOTPHandle} OtpsendHandle = {OtpsendHandle} />
                    }                    
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Modals;