import * as React from 'react';
// import Alert from '@mui/material/Alert';
import './alert.css';
import { useDispatch, useSelector } from 'react-redux'
import { closeTostCallAlert } from '../../redux/Actions/modal_action'
import Modal from 'react-bootstrap/Modal';
import Call from '../../Assets/images/Mask group.png';

const TostCallAlert = ({ title ,size,IsIcon,icon }) => {
  const dispatch = useDispatch();
  const isTostOpen = useSelector(state => state.TostCallReducer.isTostOpen);
  // console.log(isTostOpen);

  return <> {isTostOpen ? <Modal
    show={isTostOpen}
    onHide={()=> dispatch (closeTostCallAlert())}
    backdrop="static"
    keyboard={false}
    centered
    size={'sm'}
  >
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body className='text-center'>
      {!IsIcon ? <img src={Call} alt='icon' width={'70px'} className='mb-3' /> : null}<br />
      {title}
    </Modal.Body>

  </Modal> : ''}</>;
}

export default TostCallAlert;