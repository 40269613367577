import React, { useEffect } from "react";
import Header from "../Components/header";
import PageHeader from "../Components/Header/pageHeader";
import Footer from "../Components/Footer/Footer";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import '../Components/walletTransaction/wt.css';
import Icon1 from '../Assets/images/Group 1390.png';
import Icon2 from '../Assets/images/Group 1391.png';
import Icon3 from '../Assets/images/Group 1396.png';
import Gift from '../Assets/images/Group 1331.png';
import TabItem from '../Components/walletTransaction/tabaleItem';
import Wallet_Balance from "../Components/Header/balance";
import Moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWalletTransactionAction } from '../redux/Actions/walletTransactionAction';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';

export default function WalletTransactions(props) {
    const dispatch = useDispatch();
    const Wallet = useSelector(state => state.WalletTransactionReducer.Wallet) ?? [];
    const Cash = useSelector(state => state.WalletTransactionReducer.Cash) ?? [];
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    const { loading = false } = props;
    const [value, setValue] = React.useState('1');


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(getAllWalletTransactionAction(userProfile.wallet_addr));
    }, [dispatch])

    console.log(Cash);

    return <>
        <div className="desk_top">
            <Header />
        </div>

        <PageHeader title={'Wallet Transactions'} />
        <div className="container wallet_container">
            <div className="balance_container mob_480">
                <Wallet_Balance />
            </div>
            <Box sx={{ width: '100%', typography: 'body1' }} className="wallet_tab_container" >
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList className="Recharge_tab" onChange={handleChange} aria-label="lab API tabs example">
                            <Tab clas label="Deduction" value="1" />
                            <Tab label="Recharge Done" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                    {
                        Object.keys(Wallet).length !== 0 ? <>{Wallet.map((data, index) => {
                            var theDate = new Date(data.start_at * 1000);
                            var dateString = theDate.toGMTString();
                            var dd = Moment(dateString).format("DD MMM YYYY, hh:mm a");
                            return <TabItem
                                icon={data.service_type === "USR-CHT-END" ? Icon2 : data.service_type === "CALL" ? Icon1 : Gift}
                                serviceTitle={data.service_type === "USR-CHT-END" ? "Chat Deduction" : data.service_type === "CALL" ? 'Call Deduction' : 'Gift'}
                                date={dd}
                                amount={data.cost}
                                key={index}
                                Isadd={false}
                            />
                        })}</> : <> {Array.from({length:4} , (_, index) => <CardHeader key={index}
                        sx={{ border: '1px solid var(--border_color)', borderRadius: '10px', boxShadow: '0px 0px 6px 1px var(--border_color)',marginBottom:'20px' }}
                        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
                        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                        }
                        subheader={<Skeleton animation="wave" height={10} width="40%" />}
                    />) }</>
                    }
                        
                        
                        
                    </TabPanel>
                    <TabPanel value="2">
                    {
                        Object.keys(Cash).length !== 0 ? <>{Cash.map((data, index) => {
                            var theDate = new Date(data.created_at * 1000);
                            var dateString = theDate.toGMTString();
                            var dd = Moment(dateString).format("DD MMM YYYY, hh:mm a");
                            if(data.status === 'captured'){
                                return <TabItem
                                icon={Icon3}
                                serviceTitle={'Recharge Done'}
                                date={dd}
                                amount={(data.amount/100)}
                                key={index}
                                Isadd={true}
                            />
                            }
                            
                        })}</> : <> {Array.from({length:4} , (_, index) => <CardHeader key={index}
                        sx={{ border: '1px solid var(--border_color)', borderRadius: '10px', boxShadow: '0px 0px 6px 1px var(--border_color)',marginBottom:'20px' }}
                        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
                        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                        }
                        subheader={<Skeleton animation="wave" height={10} width="40%" />}
                    />) }</>
                    }
                    </TabPanel>
                </TabContext>
            </Box>

        </div>
        <div className="mt-5 mob_480">&nbsp;</div>
        <div className="desk_top">
            <Footer />
        </div>
    </>
}