import React from 'react';
import Modal from 'react-bootstrap/Modal';


function ConfirmationPopup({ Isopen, handleClose, title, modalBody ,size}) {

    return (
        <>
            <Modal
                show={Isopen}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size={size}
            >
                <Modal.Header closeButton>{title} </Modal.Header>
                <Modal.Body>
                    {modalBody}
                </Modal.Body>

            </Modal>
        </>
    );
}

export default ConfirmationPopup;