import React from 'react';
import Avatar from '@mui/material/Avatar';
import CloseBtn from '../../Assets/images/Group 1405.png';
import AvatarIcon from '../../Assets/images/pro.png';
import Preferences from '../../Assets/images/Group 1230.png'
import Wallet from '../../Assets/images/Group 1234.png'
import CallHistory from '../../Assets/images/Group 1231.png'
import ChatHistory from '../../Assets/images/Group 1235.png'
import HelpCenter from '../../Assets/images/Group 1232.png'
import ReviewUs from '../../Assets/images/Group 1236.png'
import Share from '../../Assets/images/Group 1233.png'
import Logout from '../../Assets/images/Group 1237.png'
import Links from './Links';
import FAQs from '../../Assets/images/Group 1397.png'
import Support from '../../Assets/images/Group 1398.png'
import Privacy from '../../Assets/images/Group 1399.png'
import Terms from '../../Assets/images/Group 1400.png'
import Refund from '../../Assets/images/Group 1401.png'
import Deactivate from '../../Assets/images/Group 1404.png'
import Delete from '../../Assets/images/Group 1402.png'
import Facebook from '../../Assets/images/facebook.png';
import Insta from '../../Assets/images/insta.png';
import Snap from '../../Assets/images/snap.png';
import Moj from '../../Assets/images/Moze.png';
import Josh from '../../Assets/images/Josh.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {openModal} from '../../redux/Actions/modal_action';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

const MobHeader = ({IsOpen, OnClose}) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';
    const IsUserLogdin = localStorage.getItem('isUserLoggedIn') ?? false;

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isModalOpen1, setIsModalOpen1] = React.useState(false);
    const openModal1 = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsModalOpen1(false)
    };

    const routeHandle = (type) =>{
        history(type);
    }

    const logout = () =>{
        localStorage.clear();
        window.location.reload();
        history('/')
    }

    const helpCenterData = [
        {id:'0', name:'FAQs', icon:FAQs, url:'/faqs'},
        {id:'1', name:'Support', icon:Support, url:'/support'},
        {id:'2', name:'Privacy & Policy', icon:Privacy, url:'/privacy-policy'},
        {id:'3', name:'Terms & Conditions', icon:Terms, url:'/terms-&-conditions'},
        {id:'4', name:'Refund Cancellation', icon:Refund, url:'/refund-&-cancellation-policy'},
        {id:'5', name:'Deactivate Account', icon:Deactivate, url:'/support'},
        {id:'6', name:'Delete Account ', icon:Delete, url:'/support'}
    ]

    const ShareData = [
        {id:'0', name:'Facebook', icon:Facebook, url:'https://www.facebook.com/'},
        {id:'1', name:'Instagram', icon:Insta, url:'https://www.instagram.com/'},
        {id:'2', name:'Snapchat', icon:Snap, url:'https://snap.com/'},
        {id:'3', name:'Moj', icon:Moj, url:'https://mojapp.in/'},
        {id:'4', name:'Josh', icon:Josh, url:'https://share.myjosh.in/'}
    ]
    return <> 
    
   {IsOpen ? <div className='mob_header_container'>
        <div className='header_menu_container'>
            <div className='mob_header position-relative'>
                <img src={CloseBtn} alt='close btn' onClick={OnClose} width={'30px'} className='closeBtn_img position-absolute' />
                <div className='mob_user_avatar'>
                    <Avatar alt={''} onClick={() => !IsUserLogdin ? dispatch(openModal()) : routeHandle('/profile')} src={ userProfile ? userProfile.avatar : AvatarIcon} sx={{ width: 75, height: 75 }} />
                    <div className='user_details text-white' onClick={() => !IsUserLogdin ? dispatch(openModal()) :routeHandle('/profile')}>
                        <strong className=' fs-5'>{userProfile.name ?? 'userName'}</strong>
                        <caption className='text-white'>{ userProfile ? userProfile.phones[0]:'xxxxxx0482'}</caption>
                    </div>
                </div>
            </div>
            <div className='mob_header_body'>
                <div className='navBar_'>
                    <ul className='list-unstyled p-0'>
                        <li className='mobNavLink' onClick={()=> !IsUserLogdin ? dispatch(openModal()):routeHandle('/preferences')}>
                            <span className='d-flex column-gap-3 align-items-center'>
                                <Avatar alt="Preferences" src={Preferences} sx={{ width: 35, height: 35 }} />
                                <span> Preferences </span>
                            </span>
                            <ArrowForwardIosIcon fontSize="inherit" />
                        </li>
                        
                        <li className='mobNavLink' onClick={()=> !IsUserLogdin ? dispatch(openModal()):routeHandle('/wallet-transactions')}>
                            <span className='d-flex column-gap-3 align-items-center'>
                                <Avatar alt="Wallet" src={Wallet} sx={{ width: 35, height: 35 }} />
                                <span> Wallet Transactions </span>
                            </span>
                            <ArrowForwardIosIcon fontSize="inherit" />
                        </li>
                        <li className='mobNavLink' onClick={()=> !IsUserLogdin ? dispatch(openModal()):routeHandle('/call-history')}>
                            <span className='d-flex column-gap-3 align-items-center'>
                                <Avatar alt="Call History" src={CallHistory} sx={{ width: 35, height: 35 }} />
                                <span> Call History </span>
                            </span>
                            <ArrowForwardIosIcon fontSize="inherit" />
                        </li>
                        <li className='mobNavLink' onClick={()=> !IsUserLogdin ? dispatch(openModal()):routeHandle('/chat-history')}>
                            <span className='d-flex column-gap-3 align-items-center'>
                                <Avatar alt="Chat History" src={ChatHistory} sx={{ width: 35, height: 35 }} />
                                <span> Chat History </span>
                            </span>
                            <ArrowForwardIosIcon fontSize="inherit" />
                        </li>
                        <li className='mobNavLink' onClick={openModal1}>
                            <span className='d-flex column-gap-3 align-items-center'>
                                <Avatar alt="Help Center" src={HelpCenter} sx={{ width: 35, height: 35 }} />
                                <span> Help Center </span>
                            </span>
                            <ArrowForwardIosIcon fontSize="inherit" />
                        </li>
                        <Links isOpen={isModalOpen} IsShare={false} data={helpCenterData} onClose={closeModal} />
                        <li className='mobNavLink' onClick={() => window.open('https://play.google.com/store/games?device=windows&pli=1', '_blank')}>
                            <span className='d-flex column-gap-3 align-items-center'>
                                <Avatar alt="Review Us" src={ReviewUs} sx={{ width: 35, height: 35 }} />
                                <span> Review Us </span>
                            </span>
                            <ArrowForwardIosIcon fontSize="inherit" />
                        </li>
                        <li className='mobNavLink' onClick={() => setIsModalOpen1(true)}>
                            <span className='d-flex column-gap-3 align-items-center'>
                                <Avatar alt="Share" src={Share} sx={{ width: 35, height: 35 }} />
                                <span> Share </span>
                            </span>
                            <ArrowForwardIosIcon fontSize="inherit" />
                        </li>
                        <Links isOpen={isModalOpen1} IsShare={true} data={ShareData} onClose={closeModal} />
                        <li className='mobNavLink' onClick={() => !IsUserLogdin ? dispatch(openModal()):logout()}>
                            <span className='d-flex column-gap-3 align-items-center'>
                                <Avatar alt="Logout" src={Logout} sx={{ width: 35, height: 35 }} />
                                <span> {IsUserLogdin ? 'Logout' : 'Login'} </span>
                            </span>
                            <ArrowForwardIosIcon fontSize="inherit" />
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>:null}
    </>
}

export default MobHeader;