import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

function SkeletonChildrenDemo({id}) {
  return (
    <Grid item xs width={'33%'} key={id}>
      <Box sx={{ display: 'flex', alignItems: 'center',border:'1px solid #eee', borderRadius:'10px',boxShadow:'0px 0px 10px 2px var(--border_color)',padding:'20px' }} key={id}>
        <Box sx={{ margin: 1 }}>
            <Skeleton variant="circular" width={'120px'} height={'120px'}>
              <Avatar />
            </Skeleton>
        </Box>
        <Box sx={{ width: '100%' }}>
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="40%">
              <Typography>.</Typography>
            </Skeleton>
        </Box>
      </Box>
      </Grid>
  );
}

export default SkeletonChildrenDemo;
