import * as Constant from '../constant'
import {postApi,getCommonHeaders} from '../../configuration/Apis';
import API_urls from '../../configuration/API_urls';
import axios from 'axios';
import { Loading, closeModal,openTostAlert } from './modal_action';
import { wallet_balanceAction } from './wallet_Balance_Action';

export const Fatch_user = (phone) => async (dispatch) => {
    dispatch(Loading(Constant.LOADING_TRUE));
    dispatch({ type: Constant.FETCH_USER_START });
    try {
        const url = API_urls.Fatch_user;
        const header = getCommonHeaders();
        const body = { phone, type: 'web' };
        const res = await postApi(url, header, body);
        localStorage.setItem('fatchUser', JSON.stringify(res.data));
        dispatch({ type: Constant.FETCH_USER_SUCCESS, payload: res.data });
    } catch (err) {
        console.log(err);
        dispatch({ type: Constant.FETCH_USER_FAILURE });
        dispatch(Loading(Constant.LOADING_TRUE));
        dispatch(openTostAlert(`There was a problem fetching the data:${err.message}`, 'error'));
    }
};

export const loginOtp = (userid) => async (dispatch) =>  {
    dispatch(Loading(Constant.LOADING_TRUE));
    dispatch({type: Constant.START_LOGIN_WITH_OTP});
    try {
        const url = API_urls.Login_with_otp;
        const body = { id: userid };
        const res = await axios.post(url, body); 
        dispatch({type: Constant.LOGIN_WITH_OTP, payload: res.status === 200 ? true : false});
        if(res.status === 200){
            dispatch(Loading(Constant.LOADING_FALSE));
        }
    } catch(err) {
        console.log(err);
        dispatch({type: Constant.FAILURE_LOGIN_WITH_OTP});
        dispatch(Loading({type:Constant.LOADING_FALSE}));
        dispatch(openTostAlert("It seems that we've already sent an OTP to your number. Please wait for 5 minutes before requesting another one.", "error"));
    }
};

export const verifyOTPAction = (userid,otpVal) => async (dispatch) =>  {
    dispatch(Loading(Constant.LOADING_TRUE));
    dispatch({type: Constant.START_VERIFY_OTP});
    try {
        const url = API_urls.verify_otp;
        var requestBody = {'user_id': userid,'otp': otpVal,
        };
        const res = await axios.post(url, requestBody);
        localStorage.setItem('isUserLoggedIn', true); 
        localStorage.setItem('userProfile', localStorage.getItem('fatchUser'));
        dispatch({type: Constant.VERIFY_OTP, payload: res.status === 200 ? true : false});
        if(res.status === 200){dispatch(Loading(Constant.LOADING_FALSE))};
        dispatch(closeModal());
        dispatch(openTostAlert("OTP has been successfully sent to your registered number. ", "success"));
        dispatch(wallet_balanceAction(userid));
        window.location.reload();
    } catch(err) {
        console.log(err);
        dispatch({type: Constant.FAILURE_VERIFY_OTP});
        dispatch(Loading(Constant.LOADING_FALSE));
        dispatch(openTostAlert(`There was a problem fetching the data:${err.message}`, 'error'));
    }
};
