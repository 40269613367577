import React from 'react'
import GreenDot from '../../Assets/images//Group 823.png'
import favourite from '../../Assets/images//Fev.png'
import line1 from '../../Assets/images//Vector 13.png'
import line2 from '../../Assets/images//Vector 12.png'
import Call from '../../Assets/images//Group 1227.png'
import Chat from '../../Assets/images/Group 1228.png'
import moment from 'moment'
import './card.css';
import Avatar from '@mui/material/Avatar';
// import CallButton from "../newComponents/button";

const AgentCard = ({ data, id, image, name, age, eng, description, url, IsCall, IsChat, isLine2, CallinitiateRequest, ChatinitiateRequest,IslodaingTrue,getChatHistory,inboxid,chathistroy,IsCallHistory,IsFavourite,AddToFavourite }) => {
    // console.log(data.call);
    // {var dur = (Math.ceil(data.call.cost/data.pandit.profile.rate.CALL));
    //  const durRf = dur > 0 ? ( 
    //     dur > 60 ? Math.floor(dur/60) + " hr " + (dur % 60) + " min" : dur + " min") : 'missed call';}
    return (
        <div key={id} className="product-item d-flex align-items-center position-relative">
            {IsFavourite ? <img src={favourite} onClick={() => AddToFavourite(data)} alt='favourite'  className='favourite position-absolute' /> :''}
            <div className='Agent_avatar p-2' onClick={chathistroy ? () => getChatHistory(inboxid,name):null}>
                {/* <img src={image} alt={name} /> */}
                <Avatar alt={name} src={image} className='avater_img' sx={{ width: 85, height: 85 }} />
            </div>
            <div className='Agent_details text-left'>
                <div className='greenDot_container d-flex align-items-center justify-content-between mb-2'>
                    
                    <img src={line1} alt='line' />
                    <img src={GreenDot} alt="online Grren Dot" />
                </div>
                <h5 onClick={chathistroy ? () => getChatHistory(inboxid,name):null}>{name}</h5>
                <p>{age} yrs | {eng}</p>
                <p className='text-disc'>{description}</p>
                {IsCallHistory ? <div className='callhistrory_container'>
                    <p>Date & Time : {moment(data.call.start_at *1000).format("DD MMM YYYY, hh:mm a")} </p>
                    <p><span> Call Duration : {(Math.ceil(data.call.cost/data.pandit.profile.rate.CALL)) > 0 ? ( (Math.ceil(data.call.cost/data.pandit.profile.rate.CALL)) > 60 ? Math.floor((Math.ceil(data.call.cost/data.pandit.profile.rate.CALL))) + " hr " + ((Math.ceil(data.call.cost/data.pandit.profile.rate.CALL))) % 60 + " min" : (Math.ceil(data.call.cost/data.pandit.profile.rate.CALL)) + " min") : 'missed call'}</span></p>
                </div>:null}
                <div className={`greenDot_container d-flex align-items-center mt-1 ${IsCall ? 'justify-content-between' : IsChat ? 'justify-content-between' : 'justify-content-end'}`}>
                    
                    {IsCall ? !IslodaingTrue ? <img src={Call} alt='call btn' onClick={CallinitiateRequest} width={'70px'} />:<button class="btn spinner_btn btnSpiner_container" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button> : null}

                    {/* <CallButton id={id} /> */}
                   
                    {IsChat ? <img src={Chat} alt='chat btn' onClick={() => ChatinitiateRequest(data,id)} width={'70px'} /> : null}
                    {isLine2 ? <img src={line2} alt='line' /> : null}

                    
                </div>


            </div>


        </div>
    )
}


export default AgentCard;