import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { wallet_balanceAction } from "../../redux/Actions/wallet_Balance_Action";
import '../Header/pageHeader.css';
import { useNavigate } from 'react-router-dom';
import * as Constant from '../../configuration/constent';

const Wallet_Balance = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const walletBalance = useSelector(state => (state.walletBalanceReducer.Balance ? (state.walletBalanceReducer.Balance).toFixed(2) : ''));
    const isUserLoggedIn = localStorage.getItem('isUserLoggedIn') ?? false;
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    // console.log(walletBalance);

    React.useEffect(() => {
        if (isUserLoggedIn) {
            dispatch(wallet_balanceAction(userProfile.id))
        }
    }, []);

    return <>
        {isUserLoggedIn ? <div className="balance_container">
            <div className="available_balance d-flex">
                <p><strong>Available Balance : {'₹' + walletBalance ?? '0'} </strong></p>
                <p className="rechargeBtn" onClick={() => history(Constant.FRONTEND_NAME + "/recharge")}> Recharge Now </p>
            </div>
        </div> : null}
    </>
}

export default Wallet_Balance;

