import axios from "axios";
import API_urls from "../../configuration/API_urls";
import { REQUEST_CALL_FAILURE, REQUEST_CALL_START } from "../constant"
import { openTostCallAlert, openTostAlert, Callinitiated } from "./modal_action";
import { postApi } from "../../configuration/Apis";
import { IslodaingTrueAction } from "./GetAllonlineAgentsAction";

export const InitiateCallAction = (data, balance, userMsisdn,firstTimeUser, isCallDisabled) => async (dispatch) => {
  dispatch({ type: REQUEST_CALL_START });
  try {
    // console.log("***"+ firstTimeUser);
    if(firstTimeUser === 'true'){
      // console.log('firstTimeUser'+ firstTimeUser);
      // console.log("***"+firstTimeUser);
       // Call the function once user is logged in and balance is sufficient
       dispatch(Callinitiated(true));
       if (!isCallDisabled) {
         const url = API_urls.pushIvrObd;
         const url1 = API_urls.mark_pandit_online;
         const body = { id: data.pandit.user.id, email: data.pandit.user.emails[0], activity: 'CALL' }
         const requestBody = {
           no2dial: 91 + data.pandit.user.phones[0],
           ratePerMinute: data.pandit.profile.rate.CALL,
           userBalance: (data.pandit.profile.rate.CALL * 5),
           userNumber: 91 + userMsisdn,
           subsidizedCampaignId: '',
           firstTimeUser:firstTimeUser
         };
 
         const headers = {
           'Content-Type': 'application/json',
           'clientId': 'yBMYafcnQ/OZY70+m7WwWA==',
           'campaignId': '1',
           'deviceId': 'a1b2c3d4',
           'appName': 'astrology',
           'appVersion': '1',
           'channel': 'api',
           'channelName': 'android',
           'deviceType': 'mobile',
           "countryCode": '91',
           "msisdn": userMsisdn,
           "appLanguage": "en"
         };
 
         const markpandit = await axios.post(url1, body);
         const res = await postApi(url, headers, requestBody);
         console.log(markpandit);
         if (res.status === 200) {
           dispatch(openTostCallAlert('Thank You For placing your call. We will be connecting you shortly', "success"))
           dispatch(IslodaingTrueAction(''));
         } else dispatch(openTostCallAlert('Error while initiating a call', "error"));
         setTimeout(() => {
           dispatch(Callinitiated(false));
         }, 120000)
       } else {
         dispatch(openTostAlert("It seems that we've already initiate call request to your number. Please wait for 2 minutes before requesting another one.",'warning'))
         dispatch(IslodaingTrueAction(''));
       };
    } else if (balance < data.pandit.profile.rate.CHAT_IN * 5) {
      dispatch(openTostCallAlert("Minimum balance 5 mins chat (" +
        '₹' + data.pandit.profile.rate.CHAT_IN * 5 + " for 5 mins) is required to chat with Agents.", 'warning'))
      dispatch(IslodaingTrueAction(''));
    } else {
      // Call the function once user is logged in and balance is sufficient
      dispatch(Callinitiated(true));
      console.log('else');
      if (!isCallDisabled) {
        const url = API_urls.pushIvrObd;
        const url1 = API_urls.mark_pandit_online;
        const body = { id: data.pandit.user.id, email: data.pandit.user.emails[0], activity: 'CALL' }
        const requestBody = {
          no2dial: 91 + data.pandit.user.phones[0],
          ratePerMinute: data.pandit.profile.rate.CALL,
          userBalance: balance,
          userNumber: 91 + userMsisdn,
          subsidizedCampaignId: '',
          firstTimeUser:firstTimeUser
        };

        const headers = {
          'Content-Type': 'application/json',
          'clientId': 'yBMYafcnQ/OZY70+m7WwWA==',
          'campaignId': '1',
          'deviceId': 'a1b2c3d4',
          'appName': 'astrology',
          'appVersion': '1',
          'channel': 'api',
          'channelName': 'android',
          'deviceType': 'mobile',
          "countryCode": '91',
          "msisdn": userMsisdn,
          "appLanguage": "en"
        };

        const markpandit = await axios.post(url1, body);
        const res = await postApi(url, headers, requestBody);
        console.log(markpandit);
        if (res.status === 200) {
          dispatch(openTostCallAlert('Thank You For placing your call. We will be connecting you shortly', "success"))
          dispatch(IslodaingTrueAction(''));
        } else dispatch(openTostCallAlert('Error while initiating a call', "error"));
        setTimeout(() => {
          dispatch(Callinitiated(false));
        }, 120000)
      } else {
        dispatch(openTostAlert("It seems that we've already initiate call request to your number. Please wait for 2 minutes before requesting another one.",'warning'))
        dispatch(IslodaingTrueAction(''));
      };
    }
  } catch (err) {
    console.log(err);
    dispatch({ type: REQUEST_CALL_FAILURE });
    dispatch(openTostAlert(`There was a problem while initiating a call:${err.message}`, 'error'));
  }
}