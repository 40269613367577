import axios from 'axios';
import API_urls from '../../configuration/API_urls';
import * as Constent from '../constant';
import { openTostAlert } from './modal_action';

export const wallet_balanceAction = (userid) => async (dispatch) =>{
    // console.log(userid);
    dispatch({type:Constent.FETCH_WALLET_BALANCE_START});
    try{
        const url = API_urls.wallet_balance;
        const body ={id:userid}
        const res = await axios.post(url,body);
        localStorage.setItem('Balance', res.data);
        dispatch({type:Constent.FETCH_WALLET_BALANCE, payload:res.data});
    }catch(err){
        console.log(err);
        dispatch({type:Constent.FETCH_WALLET_BALANCE_FAILURE});
        dispatch(openTostAlert(`There was a problem fetching Balance:${err.message}`, 'error'));
    }

}