import React from "react";
import Header from "../Components/header";
import Footer from "../Components/Footer/Footer";
import PageHeader from "../Components/Header/pageHeader";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import '../Style/support.css';
import Heading from "../Components/heading/heading";
import Addres from "../Assets/images/Adress.png";
import Phone from "../Assets/images/phonecall.png";
import Mail from "../Assets/images/mail.png";
import Circle from "../Assets/images/Ellipse.png";
import { useDispatch } from 'react-redux'
import { openTostAlert } from "../redux/Actions/modal_action";
export default function Support() {
    const dispatch = useDispatch()
    const [values, setValues] = React.useState({ name: '', number: '', email: '', query: '' });
    const [errors, setErrors] = React.useState({ name: false, number: false, email: false, query: false });
    const [helperTexts, setHelperTexts] = React.useState({ name: '', number: '', email: '', query: '' });


    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let valid = true;
        const newErrors = { name: false, number: false, email: false, query: false };
        const newHelperTexts = { name: '', number: '', email: '', query: '' };

        // Validate Name
        if (!values.name) {
            newErrors.name = true;
            newHelperTexts.name = 'Name is required';
            valid = false;
        }

        // Validate Number
        if (!/^\d{10}$/.test(values.number)) {
            newErrors.number = true;
            newHelperTexts.number = 'Number must be exactly 10 digits';
            valid = false;
        }

        // Validate Email
        if (!validateEmail(values.email)) {
            newErrors.email = true;
            newHelperTexts.email = 'Valid email is required';
            valid = false;
        }

        // Validate Query
        if (!values.query) {
            newErrors.query = true;
            newHelperTexts.query = 'Query is required';
            valid = false;
        }

        setErrors(newErrors);
        setHelperTexts(newHelperTexts);

        if (valid) {
            // Perform the form submission logic
            console.log('Form submitted:', values);
            dispatch(openTostAlert('Your query has been submitted successfully. We will get back to you soon.', 'success'))
            setValues({ name: '', number: '', email: '', query: '' });

        }
    };

    return <>
        <div className="desk_top">
            <Header />
        </div>
        <PageHeader title={'Support'} />

        <div className="support_container container mt-100">
            <div className="support_form_container mt-5 d-flex">
                <Box component=""
                    sx={{ width: '100%', textAlign: 'center', background: 'var(--main_color)', textAlign: 'left', padding: '20px', color: 'var(--white_color)' }}>
                    <div className="mb-5">
                        <h3> Contact Us</h3>
                        <p>Please share your issue with contact details. Our support team will get back to you with resolution within 24hrs</p>
                    </div>
                    <div className="Address_container mt-2 d-flex column-gap-2">
                        <img src={Addres} alt="Address" width={'50px'} height={'50px'} />
                        <div><p className="m-0"><strong>Ubarri Marketing Pvt Ltd </strong></p>
                            <span> A-2/72, SITE-V, UPSIDC SURAJPUR INDUSTRIAL AREA,I.A. Surajpur, Gautam Buddha Nagar, Dadri, Uttar Pradesh, India, 201306</span></div>
                    </div>

                    <div className="phone_Container mt-4 d-flex column-gap-2 align-items-center mb-3">
                        <img src={Phone} alt="phone" width={'50px'} />
                        <p className="m-0"><a href="tel:+91-9485589244" style={{ color: "var(--white_color)", textDecoration: 'none' }}>+91-9485589244</a></p>
                    </div>
                    <div className="phone_Container d-flex column-gap-2 align-items-center">
                        <img src={Mail} alt="phone" width={'50px'} />
                        <p className="m-0"><a href="mailTo:tech@ubarri.com" style={{ color: "var(--white_color)", textDecoration: 'none' }}>tech@ubarri.com</a></p>
                    </div>
                    <div className="phone_Container d-flex column-gap-2 align-items-center justify-content-end">
                        <div>
                            <img src={Circle} alt="phone" width={'50px'} />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={Circle} alt="phone" width={'100px'} />
                        </div>
                    </div>
                </Box>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 3, width: '100%', textAlign: 'center' },
                    }}
                    noValidate
                    className="d-flex justify-content-center flex-wrap"
                    onSubmit={handleSubmit}
                >
                    <TextField
                        required
                        fullWidth
                        id="name"
                        name="name"
                        label="Full Name"
                        variant="outlined"
                        value={values.name}
                        onChange={handleChange}
                        error={errors.name}
                        helperText={helperTexts.name}
                        margin="normal"
                    />
                    <TextField
                        required
                        fullWidth
                        id="number"
                        name="number"
                        label="Mobile"
                        variant="outlined"
                        value={values.number}
                        onChange={handleChange}
                        error={errors.number}
                        helperText={helperTexts.number}
                        margin="normal"
                        inputProps={{ maxLength: 10 }}
                    />

                    <TextField
                        required
                        fullWidth
                        id="email"
                        name="email"
                        label="Email Id"
                        variant="outlined"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email}
                        helperText={helperTexts.email}
                        margin="normal"
                    />
                    <TextField
                        required
                        fullWidth
                        id="query"
                        name="query"
                        label="Comment"
                        variant="outlined"
                        value={values.query}
                        onChange={handleChange}
                        error={errors.query}
                        helperText={helperTexts.query}
                        margin="normal"
                        multiline
                        rows={1}
                    />


                    <Button variant="" type="submit" className="Btn_bg btn btn-primary btn-lg w-50 text-white">submit</Button>

                </Box>

            </div>
        </div>
        <div className="desk_top">
            <Footer />
        </div>
    </>
}