import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import All from '../../Assets/images/Layer_1.png';
import Fitness from '../../Assets/images/BACKGROUND.png';
import Relationship from '../../Assets/images/Group 1433.png';
import Mental from '../../Assets/images/Group 1434.png';
import LifeStyle from '../../Assets/images/Group 1435.png';
import './category.css';


export default function Category({value,categoryHandle}) {

  return (
    <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
      <Tabs
        className='category_btn'
        value={value}
        onChange={categoryHandle}
        variant="scrollable"
        scrollButtons = {false}
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        <Tab label="All" value={'all'} icon={<img src={All} alt='all' width={'20px'} />} />
        <Tab label="Fitness Counselling" value={"fitness"} icon={<img src={Fitness} alt='all' width={'20px'} />} />
        <Tab label="Relationship Counselling" value={"relationship"} icon={<img src={Relationship} alt='all' width={'20px'} />} />
        <Tab label="Mental Health Counselling" value={"mental"} icon={<img src={Mental} alt='all' width={'25px'} />} />
        <Tab label="Life Style Counselling" value={'lifestyle'} icon={<img src={LifeStyle} alt='all' width={'20px'} />} />
      </Tabs>
    </Box>
  );
}
