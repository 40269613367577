import React from "react";
import './modal.css';
import Buttons from '../Button';

const Login = ({value,InputOnchangeHandle,OnSubmitHandle}) => {
    return  <div className='login_container'>
    <div className='head'>
        <p>Please enter your mobile number to Login/Sign Up on Talknow </p>
    </div>
    <form className='login_form_container'>
        <div className='form-group border_form'>
            <select>
                <option value={'91'}>+91</option>
                <option value={'1'}>+1</option>
            </select>
            <input type='text' onChange={(e) => InputOnchangeHandle(e)} maxLength={10} className='' placeholder='Mobile Number' value={value} required />
        </div>
        <div className='form-group text-center mt-4'>
            <Buttons title={'Get OTP'} size={'lg'} className={'Btn_bg login_btn'} onclick={OnSubmitHandle} />
        </div>
    </form>
</div>
}

export default Login;