import React from "react";
import Header from "../Components/header";
import EmptyIcon from '../Assets/images/empty.png';
import PageHeader from "../Components/Header/pageHeader";
import Footer from "../Components/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import AgentCard from '../Components/card/AgentCard';
import { IslodaingTrueAction } from '../redux/Actions/GetAllonlineAgentsAction';
import { InitiateCallAction } from '../redux/Actions/RequestCallAction';
import Category from '../Components/Category/category';
import TostCallAlert from '../Components/Alert/callAlert';
import { openModal } from "../redux/Actions/modal_action";

export default function Favourite() {
    const dispatch = useDispatch();
    const favourite = JSON.parse(localStorage.getItem('favourite')) ?? [];
    const TostMsg = useSelector(state => state.TostCallReducer.msg);
    const userBalance = useSelector(state => state.walletBalanceReducer.Balance) ?? [];
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';
    const IsLoading = useSelector(state => state.IslodaingTrueReducer.IsLoading) ?? [];
    const IsUserLogdin = localStorage.getItem('isUserLoggedIn') ?? false;
    const [filteredData, setFilteredData] = React.useState([]);
    const [value, setValue] = React.useState('all');


    const initiateCallRequest = (data) => {
        if(!IsUserLogdin){
            dispatch(openModal());
        }else dispatch(InitiateCallAction(data, userBalance, userProfile.phones[0]));
    }

    const filterByCategory = (event, expertise) => {
        setValue(expertise);
        if (expertise !== 'all') {
            const filteredByExpertise = favourite.filter(item => {
                if (item.pandit.profile && item.pandit.profile.expertise) {
                    return item.pandit.profile.expertise.includes(expertise);
                }
                return false;
            });
            setFilteredData(filteredByExpertise);
        } else setFilteredData(favourite);

    }

    return <>
        <div className="desk_top">
            <Header />
        </div>
        <TostCallAlert title={TostMsg} />
        <PageHeader title={'Favourite'} />
        <div className="container favourite_contaner">
            <div className="agents_card_container mb-5">
                <div className="slider-container chatnow_container p-2">
                    <div className='mb-4'>
                        <Category value={value} categoryHandle={filterByCategory} />
                    </div>

                    {
                        Object.keys(favourite).length === 0 ? <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: '80vh' }}>
                        <img src={EmptyIcon} alt="data not found" width={'100px'} />
                        <h3> Data not found! </h3>
                    </div> : <div className='d-flex w-100 flex-wrap column-gap-3 row-gap-3 justify-content-start'>

                                {
                                    filteredData.length !== 0 ? filteredData.map(item => {
                                        if (item.current_activity == null) {
                                            // console.log(item.current_activity);
                                            return <AgentCard data={item}
                                                id={item.pandit.user.id}
                                                image={item.pandit.user.avatar}
                                                name={item.pandit.user.name}
                                                age={'25'}
                                                eng={"Hindi,English"}
                                                description={'Talks about: Cooking, Dating, Friendship, Make-Up, Acting'}
                                                IsCall={true}
                                                IsChat={true}
                                                isLine2={false}
                                                IsFavourite={false}
                                                IslodaingTrue={IsLoading == item.pandit.user.id}
                                                // AddToFavourite={AddToFavourite}
                                                CallinitiateRequest={() => {
                                                    dispatch(IslodaingTrueAction(item.pandit.user.id));
                                                    initiateCallRequest(item);
                                                }}
                                            />
                                        }
                                    }) : favourite.map(item => {
                                        // console.log(item.current_activity);
                                        if (item.current_activity == null) {
                                            return <AgentCard data={item}
                                                id={item.pandit.user.id}
                                                image={item.pandit.user.avatar}
                                                name={item.pandit.user.name}
                                                age={'25'}
                                                eng={"Hindi,English"}
                                                description={'Talks about: Cooking, Dating, Friendship, Make-Up, Acting'}
                                                IsCall={true}
                                                IsChat={true}
                                                isLine2={false}
                                                IsFavourite={false}
                                                IslodaingTrue={IsLoading == item.pandit.user.id}
                                                // AddToFavourite={AddToFavourite}
                                                CallinitiateRequest={() => {
                                                    dispatch(IslodaingTrueAction(item.pandit.user.id));
                                                    initiateCallRequest(item);
                                                }}
                                            />
                                        }
                                    })

                                }
                            </div>
                    }

                </div>

            </div>
            
        </div>
        <div className="desk_top">
            <Footer />
        </div>
    </>
}