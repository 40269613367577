import React from "react";
import Header from "../Components/header";
import Footer from "../Components/Footer/Footer";
import PageHeader from "../Components/Header/pageHeader";

export default function PrivacyPolicy() {

  return (
    <>
      <div className="desk_top">
        <Header />
      </div>
      <PageHeader title={'Privacy Policy'} />
      <div class="container privacy_container mt-100">
        <div class="row page-body privacy_container mb-5">
          <div class="col-sm-12 col-md-12 col-lg-12 float-left padd-0 text-left  ">
            <h3 class="mt-5"><strong>Privacy Policy</strong></h3>
            <div class="termsouter mt-5">

              <p><b>1.</b> talknow values the privacy of its users and is committed to protecting their personal
                information. This Privacy Policy outlines how we collect, use, and disclose information
                obtained through the App.</p>
              <p><b>2.</b> We collect personal information you provide to us, such as your name, email address,
                and profile information, when you register for an account on the App. We may also
                collect information automatically as you navigate through the App, such as usage
                details, IP addresses, and device information.</p>

              <p><b>3.</b> We use the information we collect to provide and improve the App, communicate
                with you, and personalize your experience. We do not sell or share your personal
                information with third parties for their direct marketing purposes.
              </p>
              <p><b>4.</b> We may disclose your personal information to third-party service providers who
                assist us in operating the App or conducting our business, as long as those parties
                agree to keep this information confidential.</p>

              <p><b>5.</b> By using the App, you consent to the processing of your information as described in
                this Privacy Policy.</p>

              <p><b>6.</b> talknow may also collect non-personal information, such as aggregated data or
                anonymized information, which cannot be used to identify you. This information may
                be used for various purposes, including statistical analysis, improving the App, and
                marketing.</p>
              <p> <b>7.</b> We use cookies and similar tracking technologies to collect information and improve
                the functionality of the App. You may choose to disable cookies through your browser settings, but please note that some features of the App may not function properly if
                cookies are disabled.</p>
              <p><b>8.</b> We take reasonable measures to protect the security and integrity of your personal
                information and to prevent unauthorized access or disclosure. However, no data
                transmission over the internet or storage system can be guaranteed to be completely
                secure. Therefore, we cannot guarantee the security of your information.</p>

              <p><b>9.</b> We may update this Privacy Policy from time to time to reflect changes in our
                practices or applicable laws. We will notify you of any material changes to this Privacy
                Policy by posting the revised policy on the App or by other means as required by law.</p>
              <p><b>10.</b> If you have any questions or concerns about this Privacy Policy or our privacy
                practices, please contact us at [email address].</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mob_480">&nbsp;</div>
      <div className="desk_top">
        <Footer />
      </div>
    </>
  );
}
