import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../Assets/images/Logo.png'
import User from '../Assets/images/pro.png'
import Buttons from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../redux/Actions/modal_action';
import Avatar from '@mui/material/Avatar';
import NavIcon from '../Assets/images/navPanel.png';
import Language from '../Assets/images/language.png';
import Wallet from '../Assets/images/wallet1.png';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from 'react-router-dom';
import MobHeader from './Header/MobHeader';

const settings = ['हिंदी', 'English'];

function Header() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? "";
  const IsUserLogdin = localStorage.getItem('isUserLoggedIn') ?? false;
  // console.log(userProfile);

  const handleLogin = () => {
    dispatch(openModal());
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };



  const openModal1 = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <MobHeader IsOpen={isModalOpen} OnClose={closeModal} />
      <Container>
        <div className='d-flex align-items-center'>
          <Navbar.Brand onClick={() =>history('/')} className='desk_top'><img src={Logo} alt='Logo' width={'290px'} /></Navbar.Brand>
          <Navbar.Brand className='mob_480' onClick={openModal1}><img src={NavIcon} alt='Navicon' width={'40px'} /></Navbar.Brand>
          <Navbar.Brand onClick={() =>history('/')}  className='mob_480'><img src={Logo} alt='Logo' width={'100px'} /></Navbar.Brand>
        </div>
        <div className='mob_480'>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <img src={Language} alt='Language' width={'30px'} /> &nbsp;&nbsp;&nbsp;
              </IconButton>
            </Tooltip>
            <Menu sx={{ mt: '45px' }} id="menu-appbar" keepMounted anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
              transformOrigin={{ vertical: 'top', horizontal: 'right', }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} className='Language_item' onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>

          
          <Navbar.Brand onClick={() => history('/recharge')} className='mob_480'><img src={Wallet} alt='wallet' width={'40px'} /></Navbar.Brand>
        </div>
        {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
        <Navbar.Collapse id="navbarScroll" className='justify-content-end'>
          <Nav
            className=""
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link onClick={() => history('/')}>Home</Nav.Link>
            <Nav.Link onClick={() => history('/talknow')}>Call</Nav.Link>
            <Nav.Link onClick={() => history('/chatnow')}>Chat</Nav.Link>
            <Nav.Link onClick={() => history('/favourite')}>Favourite</Nav.Link>
            {IsUserLogdin ? <>
              <NavDropdown title={userProfile.name ?? ''} id="navbarScrollingDropdown" className='d-flex'>
                <NavDropdown.Item href="/">Dashboard</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history('/profile')}>
                  Account Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => { localStorage.clear(); window.location.reload() }}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#" disabled className='p-0'>
                <Avatar alt={userProfile.name ?? ''} src={userProfile ? userProfile.avatar : User} />
                {/* <img src= className='user_avatar' alt='Avatar' width={'50px'} /> */}
              </Nav.Link>
            </> : <Buttons className={'Btn_bg'} title={"Login"} size={'lg'} onclick={handleLogin} />}

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;