import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import {useNavigate} from 'react-router-dom';

function Dropdowns({ title, DropdownItem }) {
    const history = useNavigate();
    return (
        <DropdownButton id="dropdown-basic-button" className='dropdowan_btn_container' title={title}>
            {DropdownItem.map((item, index) => <Dropdown.Item onClick={() => title === 'Share' ? window.open(item.url, '_blank') : history(item.url)} key={index}>
                <img src={item.img} alt={item.title} width={'30px'} /> &nbsp; {item.title}
            </Dropdown.Item>
            
            )}
        </DropdownButton>
    );
}

export default Dropdowns;