import React from "react";
import Logo from "../../Assets/images/Logo.png";
import AppStore from "../../Assets/images/image 6.png";
import PlayStore from "../../Assets/images/image 7.png";
import './footer.css';
import { useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

const Footer = () => {
    const history = useNavigate();

    const navigateHandle = (type) => {
        history(`/${type}`);
    }

    return <div className="Footer_container latscht_top_container">
        <div className="container">
            <div className="page-footer footer_container row">
                <div className="col-6 col-md-6 footer-about-astrology_card">
                    <p className="footer-about-astrology"><img src={Logo} alt="" width={'250px'} /></p>
                    <p className="footer-about-astrology-content text-justify text-white">
                        Discover the joy of authentic connections and unlock a world of possibilities with Talknow. Whether you're looking to make new friends, explore different cultures, or simply enjoy stimulating conversations, we invite you to join us on this incredible journey of friendship and discovery. Download the Talknow app now and embark on an adventure of connection, growth, and endless possibilities.
                    </p>
                </div>

                <div className="col-6 col-md-6 footer-about-astrology_card mt-5">
                    <div className="footer-about-astrology_card_head text-left mt-4">
                        <p className="footer-download text-left text-white fw-bold">{('Quick Links')}</p>
                    </div>
                    <ul>
                        <li><Navbar.Brand href="/#Abouts" > {('About Us')} </Navbar.Brand></li>
                        <li onClick={() => navigateHandle('privacy-policy')}>{('Privacy Policy')}</li>
                        <li onClick={() => navigateHandle('terms-&-conditions')}>{('Terms & Conditions')} </li>
                        <li onClick={() => navigateHandle('refund-&-cancellation-policy')}>
                            {('Refund Cancellation Policy')} </li>
                        <li onClick={() => navigateHandle('support')}>{('Support')}</li>
                        {/* <li onClick={() => navigateHandle('disclaimer')}>{('Disclaimer')} </li> */}
                    </ul>
                    <div className="footer-about-astrology_card_head text-left">
                        <p className="footer-download text-left text-white fw-bold">{('Download Our App')}</p>
                    </div>
                    <ul className="d-flex column-gap-3 align-items-center">
                        <li><img src={AppStore} alt="" width={'150px'} /></li>
                        <li><img src={PlayStore} alt="" width={'150px'} /></li>
                    </ul>

                </div>
            </div>
        </div>
        <div className="footer_bottom_line mb-480 text-center" style={{ padding: '10px' }}>
            Copyright {new Date().getFullYear()} <a href={'https://talknow.club'} target="blank"> {'Talknow.club'}</a> All Rights Reserved
        </div>
    </div>
}

export default Footer;