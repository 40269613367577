import Accordion from 'react-bootstrap/Accordion';
import './FAQs.css';

const FAQSData = [
    {
        id: '0',
        title: 'What picture should I use for the profile avatar?',
        disc: 'Upload photos to Friends Chat that feature who everyone came to see: you! Ditch your friends because this isn’t about them and remove the sunglasses because they hide your face. The best pics are in focus; some say a smile goes a long way here.'
    },
    {
        id: '1',
        title: 'How do Talknow matches work?',
        disc:'Talknow allows users to create profiles, browse through other profiles, and initiate conversations through audio calls and chat. Users can also send virtual gifts to express their appreciation or affection towards their friends.'
    },
    {
        id: '2',
        title: 'Is Talknow safe to use?',
        disc: 'Yes, safety is our top priority. We employ robust security measures to protect user data and ensure a safe and secure browsing experience for all our members.'
    },
    {
        id: '3',
        title: 'How does Talknow work?',
        disc: 'talknow connects you with profiles using location-based technology based on the gender, distance, and orientation filters you set.'
    },
    {
        id: '4',
        title: 'Are Talknow profiles real?',
        disc: 'Talknow offers Photo Verification to make sure the person you’re talking to matches their photos. Verified profiles will have a blue checkmark and we strongly suggest you get one—they look good on everyone and help keep our community safe.'
    },
    {
        id: '5',
        title: 'How much does it cost to use Talknow?',
        disc: 'While downloading the app is free, Letstalk operates on a nominal platform fee, which users can pay by loading their wallet with their preferred amount. Additionally, we offer cashback promotions on wallet loads to provide added value to our users.'
    },
    {
        id: '6',
        title: 'Can I use Talknow to meet people from different states?',
        disc: "Absolutely! Talknow is designed to connect individuals from across the country. Whether you're looking to learn about different cultures or simply make new friends from diverse backgrounds, our platform welcomes users from all over the country."
    },
    {
        id: '7',
        title: 'Are there any restrictions on the types of conversations allowed on Talknow?',
        disc: 'While we encourage respectful and meaningful interactions, we do have community guidelines in place to ensure that all conversations adhere to our standards of conduct. Any inappropriate or offensive behavior is strictly prohibited, and users found violating our guidelines may face account suspension or termination.'
    },
    {
        id: '8',
        title: 'How does the gifting feature work?',
        disc: 'The gifting feature allows users to express their appreciation or affection towards their friends by sending virtual gifts such as roses, chocolates, bouquets, or rings during chat sessions.'
    },
    {
        id: '9',
        title: 'What measures are in place to ensure user safety and confidentiality?',
        disc: "At Talknow, we prioritize user privacy and security. We utilize 128-bit encryption for chatting and adhere to stringent safety protocols to safeguard our users' personal information."
    },
    {
        id: '10',
        title: 'Are there any advertisements on Talknow?',
        disc: 'No, Talknow is completely ad-free. We do not promote or sponsor any products or services to ensure a hassle-free and enjoyable experience for our users.'
    },

]

function FAQs() {
    return (
        <Accordion defaultActiveKey="0">
            {
                FAQSData.map(item => {
                    return <Accordion.Item eventKey={item.id} key={item.id}>
                    <Accordion.Header><strong>{item.title}</strong></Accordion.Header>
                    <Accordion.Body>
                        {item.disc}
                    </Accordion.Body>
                </Accordion.Item>
                })
            }
        </Accordion>
    );
}

export default FAQs;