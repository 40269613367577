import { GET_ALL_WALLET_TRANSACTION, GET_ALL_WALLET_TRANSACTION_FAILURE, GET_ALL_WALLET_TRANSACTION_START } from "../constant"

const initialState = {
    Wallet:null,
    Cash:null

}
export const WalletTransactionReducer = (state = initialState, action) => {
    // console.log(action.payload ? action.payload.wallet:'');
    switch(action.type){
        case GET_ALL_WALLET_TRANSACTION_START:
            return{...state, Wallet:null,Cash:null};
        case GET_ALL_WALLET_TRANSACTION:
            return {...state, Wallet:action.payload ? action.payload.wallet:'',Cash:action.payload ? action.payload.cash:''};
        case GET_ALL_WALLET_TRANSACTION_FAILURE:
            return {...state, Transactions:null}
        default:
            return state;
    }
} 