import React from "react";
import './modal.css';
import ChatMessage from "../../Components/chatsys/chat_Message";
import moment from 'moment';
import ModalPopup from '../chatsys/chat_window/Confirmation';

const ChatMsg = ({data, show, handleClose}) => {
    const chatWindowBody = React.useRef();
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';

    return <ModalPopup Isopen={show} 
            size={'lg'}
            handleClose={handleClose}
            modalBody={ <div ref={chatWindowBody} className="chat-window__body chat_msg_container_modal">
            {data.map(({ originIpAddress, ...props }) => {
                var theDate = new Date(props.ts * 1000);
                var dateString = theDate.toGMTString();
                var dd = moment(dateString).format("DD MMM YYYY, hh:mm a");
                return (
                    <ChatMessage
                        key={Math.random()}
                        isSameOrigin={props.from_user !== userProfile.id}
                        message={props.body}
                        dateTimeStamp={dd}
                    />
                )
            })}
        </div>}
    />
}

export default ChatMsg;