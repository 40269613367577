import axios from 'axios';
import API_urls from '../../configuration/API_urls';
import {USER_PROFILE_UPDATE_START,USER_PROFILE_UPDATE_FAILURE, USER_PROFILE_UPDATE} from '../constant';
import { Fatch_user } from './login_action';
import { openTostAlert } from './modal_action';

export const UpdateUserProfile = (userData,user_id) => async(dispatch) => {
    dispatch({ type: USER_PROFILE_UPDATE_START });
    try{
        var url = API_urls.update_profile;
            url = url.replace("<id>", user_id);
        const res = await axios.post(url,userData);
        console.log(res);
        dispatch({ type: USER_PROFILE_UPDATE, payload: res.data});
        dispatch(Fatch_user(userData.phone[0]));
        dispatch(openTostAlert("Profile Updated Successfully","success"));
    }catch(err){
        console.log(err);
        dispatch({ type: USER_PROFILE_UPDATE_FAILURE});
        dispatch(openTostAlert("Something went wrong","error"));
    }
}