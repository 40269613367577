import React from "react";
import Header from "../Components/header";
import Footer from "../Components/Footer/Footer";
import PageHeader from "../Components/Header/pageHeader";
import RechargeCard from "../Components/card/rechargeCard";
import '../Style/recharge.css';
import TextField from '@mui/material/TextField';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom'
import Stack from '@mui/material/Stack';
import {useDispatch} from 'react-redux'
import Wallet_Balance from "../Components/Header/balance";
import { openModal } from "../redux/Actions/modal_action";
const Price = [10, 20, 50, 100, 200, 500, 1000, 2000];

export default function WallerRecharge() {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [amount, setAmount] = React.useState('');
    const IsUserLogdin = localStorage.getItem('isUserLoggedIn') ?? false;

    const OnclickHandle = (value) => {
        setAmount(value);
    }

    const OnSubmitHandle = () => {
        if(IsUserLogdin){
            history('/payment',{state:{amount:amount}});
        } else dispatch(openModal());
       
    }

    return <>
        <div className="desk_top">
            <Header />
        </div>
        <PageHeader title={'Wallet Recharge'} />
            <div className="container balance_container mob_480">
                <Wallet_Balance />
            </div>
        <div className="container recharge_container">
            <div className="card_container d-flex align-items-center column-gap-3 row-gap-3 flex-wrap justify-content-start">
                {Price.map(item => <Stack direction="row" sx={{width:'23%'}} spacing={4} className="recharge_btns_container shadow">
                    <RechargeCard pricetag={item} OnclickHandle={OnclickHandle} />
                </Stack>)}
            </div>
            <div className="input_container d-flex align-items-center column-gap-0 row-gap-5 mt-5 flex-wrap">
                <div className="input_box w-50 p-2">
                    <TextField
                        className="w-100 shadow bg-white"
                        id="filled-textarea"
                        label="Amount"
                        placeholder="Enter Amount"
                        multiline
                        variant="filled"
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                    />
                </div>
                <div className="input_coupon shadow p-3 w-50 d-flex align-items-center justify-content-between">
                    <p className="m-0 text-opacity-75"> Apply Coupon Code </p>
                    <ArrowForwardIosIcon />
                </div>

                <div className="w-100 d-flex align-items-center justify-content-center">
                    <Button variant="contained" onClick={OnSubmitHandle } className="Proceed_btn"> Proceed </Button>
                </div>
            </div>
        </div>
        <div className="mt-5 mob_480">&nbsp;</div>
        <div className="desk_top">
            <Footer />
        </div>
    </>
}