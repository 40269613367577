import React, { useEffect, useRef } from 'react';
import axios from "axios";
import * as Constant from "../configuration/constent";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import API_urls from '../configuration/API_urls';

const redirectRazorPay = () => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        document.body.appendChild(script);
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
    });
};

const Payment = (props) => {
    const history = useNavigate();
    const location = useLocation();
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    const Amount = location.state?.amount ?? '10';
    const paymentInitiated = useRef(false); // Use ref to track payment initiation

    useEffect(() => {
        if (!paymentInitiated.current) { // Check if payment has not been initiated already
            redirectToPayment();
            paymentInitiated.current = true; // Set payment initiation flag using ref
        }
    }, []); // Empty dependency array ensures useEffect runs only once when component mounts

    async function redirectToPayment() {
        const result = await redirectRazorPay();

        if (result) {
            const createOrder = {
                user_id: userProfile.id,
                wallet_addr:userProfile.wallet_addr,
                amount: Amount * 100, // Convert amount to smallest currency unit
                currency: 'INR',
                service_id: 'recharge',
                source: 'Web',
            };
            const razorPayURL = API_urls.create_order;
            axios.post(razorPayURL, createOrder)
                .then(data => {
                    console.log(data);
                    if (data.status === 200) {
                        const { amount, rzp_id } = data.data;
                        const options = {
                            "key": Constant.RAZORPAY_KEY,
                            "amount": amount,
                            "currency": "INR",
                            "name": "TalkNow",
                            "description": "Wallet Recharge",
                            "image": "https://myastrotell.com/static/media/AstroIcon.png",
                            "order_id": rzp_id,
                            "prefill": {
                                "name": userProfile.name,
                                "email": userProfile.email,
                                "contact": "+" + localStorage['selectedCountryCode'] + userProfile.phones[0]
                            },
                            "theme": {
                                "color": "#8A25EF"
                            },
                            "modal": {
                                "ondismiss": function () {
                                    history(Constant.FRONTEND_NAME + "/recharge");
                                }
                            },
                            "config": {
                                display: {
                                    blocks: {
                                        banks: {
                                            name: 'Pay via UPI',
                                            instruments: [
                                                {
                                                    method: 'upi',
                                                    flow: 'intent'
                                                }
                                            ],
                                        },
                                    },
                                    sequence: ['block.banks'],
                                    preferences: {
                                        show_default_blocks: true,
                                    },
                                },
                            },
                            "callback_url": `https://astroking.net/7000/astroapi/pay_order?service_id=recharge`,
                            "redirect": false,
                        };

                        const razorPayWindow = new window.Razorpay(options);
                        razorPayWindow.open();

                        razorPayWindow.on('payment.failed', function (response) {
                            // Handle payment failure
                        })
                    }
                    else {
                        // Handle payment initiation error
                    }
                }).catch(error => {
                    // Handle payment initiation error
                    console.log(error)
                })
        }
        else {
            // Handle error when loading Razorpay script
        }
    }

    return (
        <>
            <div className='wait-grid'>
                {("Please wait, Loading...")}
            </div>
        </>
    )
}

export default Payment;
