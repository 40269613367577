import React from "react";
import AboutImg from '../../Assets/images/Group 1226.png';
import Heading from "../heading/heading";
import './about.css';

const About = () => {
    return <div className="container About_container d-flex align-items-center column-gap-5 mb-5" id="Abouts">
        <div className="about_img_container w-50">
            <img src={AboutImg} alt="About Banner" className="p-5" width={'100%'} />
        </div>
        <div className="about_content_container text-justify">
            <div className="mob_center">
                <div className="heading_in_let_make">
                    <Heading
                        title={"About Us"}
                        tagline={''}
                    />
                </div>
            </div>
            <div className="mt-100">
                <p>Welcome to Talknow - Your Ultimate Destination for Meaningful Connections! </p>
                <p>At Talknow, we're more than just a social networking platform; we're a vibrant community dedicated to fostering genuine friendships and facilitating enriching interactions among individuals countrywide. In today's fast-paced digital age, where superficial connections often prevail, we believe in the power of authentic human connections to uplift and inspire.</p>
            </div>
        </div>
    </div>
}

export default About;