import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createFocusTrap } from "focus-trap";
import ChatMessage from "../chat_Message";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./chat_window.css";
import useWebSocket, { ReadyState } from "react-use-websocket";
import Button from "@mui/material/Button";
// import moment from "moment";
import Picker from "emoji-picker-react";
// import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
// import Popup from "../../popupChat";
import { FirstMessage, FRONTEND_NAME } from "../../../configuration/constent";

import Popup2 from "./Confirmation";
import $ from "jquery";
import Avatar from "@mui/material/Avatar";
// import ReviewPopup from "../chat_window/reviewPopup";
import GiftPopup from "../chat_window/gifts";
// import localStorage from "react-secure-storage";
import sendBtn from "../../../Assets/images/Group 1330.png";
import Gift from "../../../Assets/images/Group 1331.png";
import Emoji from "../../../Assets/images/Group 816.png";
import Photo from "../../../Assets/images/Photo.png";
import GIF from "../../../Assets/images/GiF.png";
import DefaultModal from "./defaultModal";
// import Tost from "../../newComponents/tost";

const ChatWindow = ({ isOpen, position, props }) => {
  const location = useLocation();
  const history = useNavigate();
  const chatWindow = useRef();
  const chatWindowBody = useRef();
  const userInput = useRef();
  const [message, setValue] = useState("");
  const [displayEmoji, setdisplayEmoji] = useState(false);
  const [focusTrap, setFocusTrap] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [showPopUp, setshowPopUp] = useState(false);
  const [msg, setmsg] = useState("");
  const [isSuccess, setisSuccess] = useState(false);
  const [chatEnded, setchatEnded] = useState(false);
  const [chatEndMsg, setchatEndMsg] = useState();
  const [onMsg, setOnMsg] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("userProfile")) ?? "";
  // const [userProfile.id, setuserProfile.id] = useState(localStorage.getItem('user_Id'));
  const [panditMsisdn, setpanditMsisdn] = useState(
    location.state.orderDetail.pandit.user.id
  );
  const [socketUrl, setSocketUrl] = useState(
    `wss://armour.mobi:7000/ws/${userProfile.id}/${panditMsisdn}`
  );
  const [messageHistory, setMessageHistory] = useState([]);
  const [show, setShow] = useState(false);
  const [giftshow, setGiftshow] = useState(false);
  const [defaultshow, setDefaultshow] = useState(false);
  console.log(socketUrl);
  const Options = {
    shouldReconnect: false,
  };

  const {
    sendMessage,
    lastMessage,
    readyState,
    sendJsonMessage,
    getWebSocket,
  } = useWebSocket(socketUrl, Options);

  const [customReadyState, setCustomReadyState] = useState(readyState);

  const [messages, setMessages] = useState([]);

  var df =
    localStorage.getItem("data") &&
    typeof localStorage.getItem("data") !== undefined
      ? JSON.parse(localStorage.getItem("data"))
      : [];

  // console.log(df);

  const HandleShow = () => setShow(true);
  const Handleclose = () => setShow(false);

  useEffect(() => {
    if (lastMessage !== null) {
      var lastMsg = JSON.parse(lastMessage.data);
      setMessageHistory((prev) =>
        prev.concat(
          lastMessage.constructor.name === Object
            ? JSON.parse(lastMessage.split("|")[0]).body
            : {
                message: lastMsg.body,
                from: userProfile.id,
                to: panditMsisdn,
                dateTimeStamp: lastMsg.timestamp,
                state: 1,
                originIpAddress: "pandit ji",
              }
        )
      );

      setMessages([
        ...messages,
        {
          message: lastMsg.body,
          from: userProfile.id,
          to: panditMsisdn,
          dateTimeStamp: lastMsg.timestamp * 1000,
          state: 1,
          originIpAddress: "pandit ji",
        },
      ]);
      localStorage.setItem(
        "data",
        JSON.stringify([
          ...Array.from(JSON.parse(localStorage.getItem("data"))),
          {
            message: lastMsg.body,
            from: userProfile.id,
            to: panditMsisdn,
            dateTimeStamp: lastMsg.timestamp * 1000,
            state: 1,
            originIpAddress: "pandit ji",
          },
        ])
      );
    }
  }, [lastMessage]);

  const ChatEnded = () => {
    if (lastMessage !== null) {
      localStorage.removeItem("chatKey");
      localStorage.removeItem("data");
      localStorage.removeItem("chatFrom");
      localStorage.removeItem("route");
      localStorage.removeItem("startTime");
      handleDisconnectClick();
      getWebSocket().close();
      props.history.push({
        pathname: FRONTEND_NAME + "/chatnow",
        state: { typeOfService: "Chat" },
      });
    } else {
      localStorage.removeItem("chatKey");
      localStorage.removeItem("data");
      localStorage.removeItem("chatFrom");
      localStorage.removeItem("route");
      localStorage.removeItem("startTime");

      props.history.push({
        pathname: FRONTEND_NAME + "/chatnow",
        state: { typeOfService: "Chat" },
      });
      handleDisconnectClick();
      getWebSocket().close();
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    setValue((inputMessage) => inputMessage + emojiObject.emoji);
  };

  const handleDisconnectClick = React.useCallback(() => {
    if (readyState === ReadyState.OPEN) {
      getWebSocket().close(1000);
      setCustomReadyState(ReadyState.CLOSED);
    }
  }, [readyState, sendJsonMessage]);

  useEffect(() => {
    var chatKey = localStorage.getItem("chatKey");
    if (chatKey === null) {
      var fmsg = FirstMessage;
      fmsg = fmsg.replace("<name>", userProfile.name);
      const FirstMessageBody = {
        from: userProfile.id,
        to: panditMsisdn,
        body: fmsg,
        state: 0,
        timestamp: Date.now(),
      };
      // handleClickSendMessage(FirstMessageBody)
      setMessages([
        ...messages,
        {
          message: FirstMessageBody.body,
          from: userProfile.id,
          to: panditMsisdn,
          dateTimeStamp: Date.now(),
          state: 1,
          originIpAddress: "pandit ji",
        },
      ]);
      localStorage.setItem(
        "data",
        JSON.stringify([
          {
            message: FirstMessageBody.body,
            from: userProfile.id,
            to: panditMsisdn,
            dateTimeStamp: Date.now(),
            state: 1,
            originIpAddress: "pandit ji",
          },
        ])
      );
    }
  }, []);

  const requestBody = {
    from: userProfile.id,
    to: panditMsisdn,
    body: message,
    state: 0,
    timestamp: Date.now(),
  };

  const handleClickSendMessage = (SendMessages) => {
    sendMessage(JSON.stringify(SendMessages));
  };

  useEffect(() => {
    setDefaultshow(true);
  }, [defaultshow]);
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Connected",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClickSendMessage(requestBody);
    onMessageSent({
      message,
      originIpAddress: ipAddress,
      dateTimeStamp: Date.now(),
    });
    localStorage.setItem(
      "data",
      JSON.stringify([
        ...Array.from(JSON.parse(localStorage.getItem("data"))),
        { message, originIpAddress: ipAddress, dateTimeStamp: Date.now() },
      ])
    );
    // console.log(lastMessage)
    setValue("");
  };

  const showPopUpFun = (msg, isSuccess) => {
    setmsg(msg);
    setisSuccess(isSuccess);
    setshowPopUp(true);
  };
  const setChatWindowScrollPosition = () => {
    const _chatWindowBody = chatWindowBody.current;
    _chatWindowBody.scrollTop = _chatWindowBody.scrollHeight;
  };

  const autExpandInput = () => {
    const _userInput = userInput.current;
    _userInput.style.height = "auto";
    _userInput.style.height = `${_userInput.scrollHeight}px`;
  };

  const onMessageSent = (message) => {
    setMessages([...messages, { ...message, dateTimeStamp: Date.now() }]);
  };

  useEffect(() => {
    setIpAddress(undefined);
    setFocusTrap(
      createFocusTrap(chatWindow.current, {
        clickOutsideDeactivates: true,
        fallbackFocus: chatWindow.current,
      })
    );
  }, []);

  useEffect(() => {
    if (!focusTrap) return;

    if (isOpen) {
      focusTrap.activate();
    } else {
      focusTrap.deactivate();
    }
  }, [isOpen, focusTrap]);

  useEffect(() => {
    setChatWindowScrollPosition();
  }, [messages]);

  useEffect(() => {
    autExpandInput();
  }, [message]);

  const closePopUpFun1 = () => {
    // alert(Routemsg)
    if (typeof localStorage.getItem("route") === undefined) {
      props.history.push({
        pathname: FRONTEND_NAME + "/chat-with-astrologer",
        state: { typeOfService: "Chat" },
      });
    } else {
      $("#exampleModalCenter").modal("hide");
    }
    setshowPopUp(false);
    var url =
      typeof localStorage.getItem("route") === "undefined" ? "home" : "chat";
  };
  console.log(connectionStatus);
  return (
    <>
      <div
        ref={chatWindow}
        className={classnames("chat-window", {
          "is-open": isOpen,
          [`chat-window--${position}`]: position,
        })}
      >
        <Popup2
          Isopen={show}
          handleClose={Handleclose}
          chatEndHandle={ChatEnded}
          modalBody={
            <>
              <div>
                <p>
                  <strong>End Chat</strong>
                </p>
                <p class="text-center">Are you sure want to end the chat?</p>
              </div>
              <div className="d-flex align-items-center justify-content-center p-3">
                <Button variant="outlined" onClick={Handleclose}>
                  {" "}
                  No
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button variant="contained" onClick={() => ChatEnded()}>
                  YES
                </Button>
              </div>
            </>
          }
        />

        <DefaultModal Isopen={defaultshow} handleClose={() => {
                history('/chatnow'); 
                setDefaultshow(false)
                }} data={location.state.orderDetail} />

        <div className="wt-100 d-flex justify-content-between align-items-center agent_container">
          <span
            className={
              customReadyState !== 3
                ? connectionStatus === "Connected"
                  ? "online_dot desk_top"
                  : "offline_dot desk_top"
                : "offline_dot"
            }
          >
            {customReadyState !== 3 ? (
              connectionStatus === "Connected" ? (
                <>&nbsp;&nbsp;&nbsp;{"Online"}</>
              ) : (
                <>&nbsp;&nbsp;&nbsp;{"Offline"} </>
              )
            ) : (
              new Date()
            )}{" "}
          </span>
          <div className="d-flex align-items-center column-gap-3 text-left p-2">
            <Avatar
              alt={location.state.orderDetail.pandit.user.name}
              src={location.state.orderDetail.pandit.user.avatar}
              className="avater_img user_img_agent"
              sx={{ width: 85, height: 85 }}
            />

            <div className="userProfile_content">
              <p className="m-0 agent_name">
                <strong>{location.state.orderDetail.pandit.user.name}</strong>
              </p>
              <p className="m-0 agent_age desk_top">25yrs | Hin,Eng </p>
              <p className="m-0 agent_age mob_480">
                <span className="d-flex align-items-center">
                  {customReadyState !== 3 ? (
                    connectionStatus === "Connected" ? (
                      <>
                        {" "}
                        {"Online"}{" "}
                        <div
                          className={
                            customReadyState !== 3
                              ? connectionStatus === "Connected"
                                ? "online_dot"
                                : "offline_dot"
                              : "offline_dot"
                          }
                        >
                          {" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                      </>
                    ) : (
                      <>
                        {"Offline"}{" "}
                        <div
                          className={
                            customReadyState !== 3
                              ? connectionStatus === "Connected"
                                ? "online_dot d-in-block"
                                : "offline_dot"
                              : "offline_dot"
                          }
                        >
                          {" "}
                          &nbsp;
                        </div>{" "}
                      </>
                    )
                  ) : null}{" "}
                </span>
              </p>
            </div>
          </div>
          <button
            className="endBtn_for_chat mt-2 mr-2 mb-2"
            onClick={() => {
              HandleShow();
            }}
          >
            {"Chat end"}
          </button>
        </div>

        {/* <ReviewPopup title={t('Your_review')} props={props} userMsisdn ={userProfile.id} reviewName={location.state.orderDetail.pandit.user.name} /> */}

        <div ref={chatWindowBody} className="chat-window__body">
          {df.map(({ originIpAddress, ...props }) => {
            return (
              <ChatMessage
                key={Math.random()}
                isSameOrigin={originIpAddress === ipAddress}
                {...props}
              />
            );
          })}
        </div>
        <div className="chat-window__footer">
          {
            <span className="errorMsg text-left ml-2 mb-2">
              *You are in the queue! Agent's first reply is awaited. You will
              not be charged till then.
            </span>
          }
          {displayEmoji ? (
            <Picker
              onEmojiClick={onEmojiClick}
              open={displayEmoji}
              width={"100%"}
            />
          ) : null}
          <form onSubmit={handleSubmit}>
            <div className="mob_480">
              {!displayEmoji ? (
                <img
                  src={Emoji}
                  alt="gift"
                  onClick={() => setdisplayEmoji(true)}
                  width={"30px"}
                  height={"30px"}
                />
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={() => setdisplayEmoji(false)}
                  size="large"
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </IconButton>
              )}
            </div>
            <input
              type="text"
              ref={userInput}
              className="chat-window__input"
              rows="1"
              placeholder="Start a new message"
              value={message}
              onChange={handleChange}
            />
            <div className="mob_480">
              <img
                src={GIF}
                alt="gift"
                onClick={() => setdisplayEmoji(true)}
                width={"25px"}
                height={"25px"}
              />
              &nbsp;&nbsp;&nbsp;
              <img
                src={Photo}
                alt="gift"
                onClick={() => setdisplayEmoji(true)}
                width={"25px"}
                height={"25px"}
              />
            </div>
            <button className="chat-window__send-btn" type="submit">
              <img src={sendBtn} alt="button" width={"40px"} />
            </button>
          </form>

          <div className="bntGroup_container desk_top">
            <IconButton
              onClick={() => setGiftshow(true)}
              aria-label="delete"
              size="small"
            >
              {" "}
              <img src={Gift} alt="gift" width={"50px"} />
            </IconButton>

            {displayEmoji ? (
              <IconButton
                aria-label="delete"
                onClick={() => setdisplayEmoji(false)}
                size="large"
              >
                {" "}
                <CloseIcon fontSize="inherit" />{" "}
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setdisplayEmoji(true)}
                aria-label="delete"
                size="small"
              >
                {" "}
                <img src={Emoji} alt="gift" width={"50px"} />
              </IconButton>
            )}
          </div>
        </div>
        <GiftPopup
          title={"Virtual Gift With Talknow"}
          props={props}
          AgentId={location.state.orderDetail.pandit.user.id}
          show={giftshow}
          Handleclose={() => setGiftshow(false)}
        />
        {/* <Tost title={location.state.orderDetail.pandit.user.name} /> */}
      </div>
    </>
  );
};

ChatWindow.propTypes = {
  isOpen: PropTypes.bool,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      originIpAddress: PropTypes.string,
      message: PropTypes.string,
      dateTimeStamp: PropTypes.string,
    })
  ),
  onClose: PropTypes.func,
  onMessageSent: PropTypes.func,
  position: PropTypes.oneOf([
    "top-right",
    "top-left",
    "bottom-right",
    "bottom-left",
  ]),
  title: PropTypes.string,
};

ChatWindow.defaultProps = {
  isOpen: false,
  position: "bottom-right",
};

export default ChatWindow;
