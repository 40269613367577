import React from "react";
import Slider from "react-slick";
import Banner from '../../Assets/images/Group 1336.png';
import Banner1 from '../../Assets/images/Group 1387.png';
import Banner2 from '../../Assets/images/Group 1389.png';
import './carousel.css';
import { useNavigate } from "react-router-dom";

function Carousel({ data, row }) {
  const history = useNavigate();

  const settings = {
    dots: true,
    arrows:false,
    lazyLoad: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "120px",
    slidesToShow: 1,
    speed: 500,
    row: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
          slidesToScroll: 1,
          arrows:false,
        }
      }
    ]
  };


  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="sliderItem" onClick={() => history('/chatnow')}>
          <img src={Banner} alt="banner" width={'100%'} />
        </div>
        <div className="sliderItem" onClick={() => history('/talknow')}>
          <img src={Banner1} alt="banner1" width={'100%'} />
        </div>
        <div className="sliderItem">
          <img src={Banner2} alt="banner2" width={'100%'} />
        </div>
      </Slider>
    </div>
  );
}

export default Carousel;
