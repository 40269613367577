import * as Constent from '../constant';

const initialState = {
  userProfile: null,
  loading: false,
  error: null,
}

const OTPSent = {
    sentOTP:false,
    loading: false,
    error: null,
    count:0
}
const VerifyOTP = {
    isUserLoggedIn:false
}

const loading = {
    isLoading:false
}

export const FatchUserReducer = (state = initialState, action) => {
    switch (action.type) {
      case Constent.FETCH_USER_START:
        return { ...state, loading: true, error: null};
      case Constent.FETCH_USER_SUCCESS:
        return { ...state, userProfile: action.payload, loading: false, };
      case Constent.FETCH_USER_FAILURE:
        return { ...state, loading: false, error: 'Failed to fetch user' };
      default:
        return state;
    }
  };

  export const loginReducer = (state = OTPSent, action) => {
    // console.log(action.payload);
    switch (action.type) {
      case Constent.START_LOGIN_WITH_OTP:
        return { ...state, loading: true, error: null };
      case Constent.LOGIN_WITH_OTP:
        return { ...state, sentOTP:action.payload, count:300, loading: false };
      case Constent.FETCH_USER_FAILURE:
        return { ...state, loading: false, error: 'Failed to fetch user' };
      default:
        return state;
    }
  };

  export const veriftOTPReducer = (state = VerifyOTP, action) => {
    // console.log(action.payload);
    switch (action.type) {
      case Constent.START_VERIFY_OTP:
        return { ...state, loading: true, error: null };
      case Constent.VERIFY_OTP:
        return { ...state, isUserLoggedIn:action.payload, loading: false };
      case Constent.FAILURE_VERIFY_OTP:
        return { ...state, loading: false, error: 'Failed to fetch user' };
      default:
        return state;
    }
  };

  export const LoadingReducer = (state = loading, action) => {
    switch (action.type) {
      case Constent.LOADING_TRUE:
        return { ...state, isLoading: true };
      case Constent.LOADING_FALSE:
        return { ...state, isLoading: false };
      default:
        return state;
  }
}