export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const LOADING_TRUE = 'LOADING_TRUE';
export const LOADING_FALSE = 'LOADING_FALSE';
export const CALL_INITIATED = 'CALL_INITIATED'; 
export const CALL_INITIATED_WAIT = 'CALL_INITIATED_WAIT';
export const OPEN_TOST_ALERT = 'OPEN_TOST_ALERT';
export const CLOSE_TOST_ALERT = 'CLOSE_TOST_ALERT';
export const OPEN_TOST_CALL_ALERT = 'OPEN_TOST_CALL_ALERT';
export const CLOSE_TOST_CALL_ALERT = 'CLOSE_TOST_CALL_ALERT';
export const FATCH_USER = 'FATCH_USER';
export const LOGIN_WITH_OTP = 'LOGIN_WITH_OTP';
export const START_LOGIN_WITH_OTP = 'START_LOGIN_WITH_OTP';
export const FAILURE_LOGIN_WITH_OTP = 'FAILURE_START_LOGIN_WITH_OTP';
export const START_VERIFY_OTP= 'START_VERIFY_OTP';
export const VERIFY_OTP= 'VERIFY_OTP';
export const FAILURE_VERIFY_OTP= 'FAILURE_VERIFY_OTP';
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_WALLET_BALANCE_START = 'FETCH_WALLET_BALANCE_START';
export const FETCH_WALLET_BALANCE = 'FETCH_WALLET_BALANCE';
export const FETCH_WALLET_BALANCE_FAILURE = 'FETCH_WALLET_BALANCE_FAILURE';
export const GET_ALL_ONLINE_AGENTS="GET_ALL_ONLINE_AGENTS";
export const GET_ALL_ONLINE_AGENTS_START="GET_ALL_ONLINE_AGENTS_START";
export const GET_ALL_ONLINE_AGENTS_FAILURE="GET_ALL_ONLINE_AGENTS_FAILURE";
export const GET_ALL_WALLET_TRANSACTION_START="GET_ALL_WALLET_TRANSACTION_START";
export const GET_ALL_WALLET_TRANSACTION="GET_ALL_WALLET_TRANSACTION";
export const GET_ALL_WALLET_TRANSACTION_FAILURE="GET_ALL_WALLET_TRANSACTION_FAILURE";
export const REQUEST_CALL_START="GET_ALL_WALLET_TRANSACTION_FAILURE";
export const REQUEST_CALL="GET_ALL_WALLET_TRANSACTION_FAILURE";
export const REQUEST_CALL_FAILURE="GET_ALL_WALLET_TRANSACTION_FAILURE";
export const ISLOADINGTRUE="ISLOADINGTRUE";
export const ISLOADINGTRUE_FAILURE="ISLOADINGTRUE_FAILURE";
export const ALL_AGENTS_START="ALL_AGENTS_START";
export const ALL_AGENTS_="ALL_AGENTS_";
export const ALL_AGENTS_FAILURE="ISLOADINGTRUE_FAILURE";
export const GET_AGENTS_CHAT_HISTORY_START="GET_AGENTS_CHAT_HISTORY_START";
export const GET_AGENTS_CHAT_HISTORY="GET_AGENTS_CHAT_HISTORY";
export const GET_AGENTS_CHAT_HISTORY_FAILURE="GET_AGENTS_CHAT_HISTORY_FAILURE";
export const GET_AGENTS_CHAT_MSG_START="GET_AGENTS_CHAT_MSG_START";
export const GET_AGENTS_CHAT_MSG="GET_AGENTS_CHAT_MSG";
export const GET_AGENTS_CHAT_MSG_FAILURE="GET_AGENTS_CHAT_MSG_FAILURE";
export const WALLET_RECHARGE="WALLET_RECHARGE";
export const WALLET_RECHARGE_FAILURE="WALLET_RECHARGE_FAILURE";
export const UPDATE_STREAM_DATA = 'UPDATE_STREAM_DATA';
export const ADD_TO_FAVOURITE_START = 'ADD_TO_FAVOURITE_START';
export const ADD_TO_FAVOURITE = 'ADD_TO_FAVOURITE';
export const ADD_TO_FAVOURITE_FAILURE = 'ADD_TO_FAVOURITE_FAILURE';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const USER_PROFILE_UPDATE_START="USER_PROFILE_UPDATE_START";
export const USER_PROFILE_UPDATE="USER_PROFILE_UPDATE";
export const USER_PROFILE_UPDATE_FAILURE="USER_PROFILE_UPDATE_FAILURE";
export const IS_USER_FIRST = "IS_USER_FIRST";
export const IS_USER_SECOND = "IS_USER_SECOND";