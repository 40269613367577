import axios from 'axios';
import API_urls from '../../configuration/API_urls'
import { ALL_AGENTS_, ALL_AGENTS_FAILURE, ALL_AGENTS_START, GET_AGENTS_CHAT_HISTORY_FAILURE, GET_AGENTS_CHAT_HISTORY_START, GET_AGENTS_CHAT_HISTORY, GET_ALL_ONLINE_AGENTS, GET_ALL_ONLINE_AGENTS_FAILURE, GET_ALL_ONLINE_AGENTS_START, ISLOADINGTRUE, GET_AGENTS_CHAT_MSG_START, GET_AGENTS_CHAT_MSG_FAILURE, GET_AGENTS_CHAT_MSG, ADD_TO_FAVOURITE_START, ADD_TO_FAVOURITE_FAILURE, ADD_TO_FAVOURITE, ADD_TO_CART, REMOVE_FROM_CART, UPDATE_QUANTITY} from '../constant'
import { openTostAlert } from './modal_action';


function sortByDateDesc (array, dateField){
    return array.sort((a, b) => b[dateField] - a[dateField]);
}
export const GetAllAgentAction = () => async (dispatch) => {
    dispatch({ type: GET_ALL_ONLINE_AGENTS_START });
    try {
        var url = API_urls.Get_all_online_Agent;
        const eventSource = new EventSource(url);
        eventSource.addEventListener('online', ({ data }) => {
            // console.log(JSON.parse(data));
            dispatch({ type: GET_ALL_ONLINE_AGENTS, payload: JSON.parse(data) })
        });
    } catch (err) {
        console.log(err)
        dispatch({ type: GET_ALL_ONLINE_AGENTS_FAILURE });
    };
};

export const IslodaingTrueAction = (userId) => {
    return { type: ISLOADINGTRUE, payload: userId }
}

export const CallHistoryAction = (userid) => async (dispatch) => {

    dispatch({ type: ALL_AGENTS_START });
    try {
        var url = API_urls.AllAgents;
        const res = await axios.post(url);
        // console.log(res.data);
        var url1 = API_urls.Wallet_transactions;
        const body = { id: userid };
        const data = await axios.post(url1, body);
        // console.log(data);
        const call_data = data.data.wallet.filter(item => item.service_type === "CALL");
        const search_agents = res.data.reduce((accum, curr) => ({ [curr.user.id]: curr, ...accum }), {});
        const call_with_agents = call_data.map(i => ({ pandit: search_agents[i.to_id], call: i }));
        // console.log(call_with_agents);
        dispatch({ type: ALL_AGENTS_, payload: call_with_agents });

    } catch (err) {
        console.log(err);
        dispatch({ type: ALL_AGENTS_FAILURE });
    }
}

export const ChatHistoryAction = (user_id) => async (dispatch) => {
    dispatch({ type: GET_AGENTS_CHAT_HISTORY_START });
    try {
        var inboxurl = API_urls.inboxes;
        var agentsurl = API_urls.AllAgents;
        const body = { id: user_id }
        const inboxes = await axios.post(inboxurl, body);
        const Agents = await axios.post(agentsurl);
        let search_agents = Agents.data.reduce((accum, curr) => ({ [curr.user.id]: curr, ...accum }), {});
        var dd = inboxes.data.map(({ id, to_user }) => ({ inbox_addr: id, ...search_agents[to_user]}));
        dispatch({ type: GET_AGENTS_CHAT_HISTORY, payload: { chatHistory: dd } });
    } catch (err) {
        console.log(err);
        dispatch({ type: GET_AGENTS_CHAT_HISTORY_FAILURE })
    }
}

export const GetChatMsgAction = (inbox_id) => async (dispatch) => {
    dispatch({type:GET_AGENTS_CHAT_MSG_START});
    try {
        var url = API_urls.chat_history;
        const body = { id: inbox_id };
        const res = await axios.post(url, body);
        // Get today's date
        const today = new Date().toLocaleDateString();

        // Filter items with today's date
        const todayItems = res.data.filter(item => {
            const itemDate = new Date(item.ts * 1000).toLocaleDateString();
            return itemDate === today;
        });

        // Sort items based on today's date
        todayItems.sort((a, b) => new Date(a.ts * 1000) - new Date(b.ts * 1000));

        // Filter items without today's date
        const otherItems = res.data.filter(item => {
            const itemDate = new Date(item.ts * 1000).toLocaleDateString();
            return itemDate !== today;
        });

        // Sort items based on today's date
        otherItems.sort((a, b) => new Date(a.ts * 1000) - new Date(b.ts * 1000));

        // Concatenate today's items with other items
        const sortedItems = otherItems.concat(todayItems);
        console.log(sortedItems);
        dispatch({type:GET_AGENTS_CHAT_MSG,payload:sortedItems});

    } catch (err) {
        console.log(err);
        dispatch({type:GET_AGENTS_CHAT_MSG_FAILURE});
    }
}

export const addToCart = (item) => (dispatch, getState) => {
    const { cart } = getState();
    const existingItem = cart.items.find((cartItem) => cartItem.pandit.user.id === item.pandit.user.id);
    if (existingItem) {
      // Item already exists
      dispatch(openTostAlert('Agent already exists in Favourite'))
    } else {
      // Item doesn't exist, add to Favourite
      dispatch({
        type: ADD_TO_CART,
        payload: item,
      });
    }
  };


//   export const AddToFavourite = (data) =>{
//     return {
//         type:ADD_TO_FAVOURITE,
//         payload:data
//     }
//   }