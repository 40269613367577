import React from 'react';
import '../Style/profile.css';
import Header from '../Components/header';
import PageHeader from '../Components/Header/pageHeader';
import Footer from '../Components/Footer/Footer';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import user from '../Assets/images/pro.png';
import Button from '@mui/material/Button';
import {UpdateUserProfile} from '../redux/Actions/userProfileAction';
import {useDispatch} from 'react-redux';

export default function UserProfile() {
    const dispatch = useDispatch();
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';
    const [gender='Male', bio='Your Bio', interests='Women'] = userProfile.pob.split(';').map(i => i.trim());
    console.log(interests);

    const [user, setUser] = React.useState({
        name:null,
        gender:null,
        bio:null,
        interests:null,
        address:null        
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = {
            name: user.name ?? userProfile.name,
            email: userProfile ? userProfile.emails[0]: '',
            phone: userProfile ? userProfile.phones: '',
            gender: user.gender ?? gender,
            bio: user.bio ?? bio,
            interests:user.interests ?? interests,
            avatar: userProfile ? userProfile.avatar: '',
            birth_ts: userProfile ? userProfile.birth_ts: '',
            city:user.address ?? userProfile.address
        }
        dispatch(UpdateUserProfile(body,userProfile.id));
        // console.log(body);
        setUser({
            name:null,
            gender:null,
            bio:null,
            interest:null,
            address:null
        });
    }
    return <>
        <div className='desk_top'>
            <Header />
        </div>
        <PageHeader title={'Profile Setting'} />
        <div className='profile_container container'>
            <div className='profile_form_container'>
                <form className='form_container position-relative shadow'>
                    <div className='w-100 d-flex justify-content-center'>
                        <Avatar src={ userProfile ? userProfile.avatar: user} className='UserAvatar_img' sx={{ width: 130, height: 130 }} />
                    </div>
                    <Box
                        className='Agents_Profile_container'
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 2, width: '30%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-basic" name='name' label="Full Name" onChange={handleChange} variant="outlined" value={user.name ?? userProfile.name} />

                        <TextField id="outlined-gender" name='gender' select label="Gender" onChange={handleChange} defaultValue={user.gender ?? gender}
                            helperText="Please select your Gender"
                        >
                            <MenuItem key={''} value={'Male'}>Male</MenuItem>
                            <MenuItem key={''} value={'Female'}>Female</MenuItem>
                            <MenuItem key={''} value={'Other'}>Other</MenuItem>
                        </TextField>
                        
                        <TextField id="outlined-basic"onChange={handleChange}  disabled label="Mobile No" defaultValue={userProfile.phones[0]} variant="outlined" />

                        <TextField id="outlined-basic" name='address' onChange={handleChange} label="City" value={user.address ?? userProfile.address} variant="outlined" />

                        <TextField id="outlined-basic" name='bio' onChange={handleChange} defaultValue={user.bio ?? bio} label="Bio" variant="outlined" />

                        <TextField id="outlined-Interest" name='interests' onChange={handleChange} defaultValue={user.interests ?? interests} select label="Interest"
                            helperText="Please select your Interest"
                        >
                            <MenuItem key={'Men'} value={'Men'}>Men</MenuItem>
                            <MenuItem key={'Women'} value={'Women'}>Women</MenuItem>
                        </TextField>
                    </Box>
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <Button variant="contained" className="Proceed_btn" onClick={handleSubmit}> Update  </Button>
                    </div>
                </form>
            </div>
        </div>
        <div className="mt-5 mob_480">&nbsp;</div>
        <div className='desk_top'>
            <Footer />
        </div>
    </>
}