import React, { useEffect } from "react";
import Header from "../Components/header";
import PageHeader from "../Components/Header/pageHeader";
import Footer from "../Components/Footer/Footer";
import { useDispatch, useSelector } from 'react-redux'
import { CallHistoryAction } from "../redux/Actions/GetAllonlineAgentsAction";
import Grid from '@mui/material/Grid';
import SkeletonChildren from '../Components/card/SkeletonCard';
import AgentCard from '../Components/card/AgentCard';
import { IslodaingTrueAction } from '../redux/Actions/GetAllonlineAgentsAction';
import { InitiateCallAction } from '../redux/Actions/RequestCallAction';


export default function CallHistroy() {
    const dispatch = useDispatch();
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';
    const CallHistoryData = useSelector(state => state.GetCallHistoryReducer.callHistory) ?? [];
    const IsLoading = useSelector(state => state.IslodaingTrueReducer.IsLoading) ?? [];
    const userBalance = useSelector(state => state.walletBalanceReducer.Balance) ?? [];
    CallHistoryData.sort((a, b) => b.call.start_at - a.call.start_at);
    // console.log(CallHistoryData);

    useEffect(() => {
        dispatch(CallHistoryAction(userProfile.wallet_addr));
    }, [dispatch]);

    const initiateCallRequest = (data) => {
        
        dispatch(InitiateCallAction(data, userBalance, userProfile.phones[0]));
    }

    return <>
        <div className="desk_top">
            <Header />
        </div>
        <PageHeader title={'Call History'} />
        <div className='container'>
            <div className="agents_card_container mb-5">
                <div className="slider-container mt-5 p-2">
                    {
                        Object.keys(CallHistoryData).length === 0 ? <Grid container spacing={3}>
                            {Array.from({ length: 6 }, (_, index) => <SkeletonChildren key={index} />)}</Grid>

                            : <div className='d-flex w-100 flex-wrap column-gap-3 row-gap-3 justify-content-start'>
                                {
                                    CallHistoryData.map(item => {
                                        // console.log(item);
                                        if (item.pandit !== undefined) {
                                            // console.log(item.call);
                                            return <AgentCard data={item}
                                                id={item.pandit.user.id}
                                                image={item.pandit.user.avatar}
                                                name={item.pandit.user.name}
                                                age={'25'}
                                                eng={"Hindi,English"}
                                                description={''}
                                                chathistroy={false}
                                                IsCallHistory={true}
                                                IsCall={true}
                                                isLine2={true}
                                                IslodaingTrue={IsLoading == item.pandit.user.id}
                                                IsFavourite={false}
                                                // AddToFavourite={AddToFavourite}
                                                CallinitiateRequest={() => {
                                                    dispatch(IslodaingTrueAction(item.pandit.user.id));
                                                    initiateCallRequest(item);
                                                }}
                                            />
                                        }

                                    }
                                    )
                                }
                            </div>
                    }

                </div>

            </div>
        </div>
        <div className="mt-5 mob_480">&nbsp;</div>
        <div className="desk_top">
            <Footer />
        </div>

    </>
}