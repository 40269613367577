// reducers.js
import { OPEN_MODAL, CLOSE_MODAL, OPEN_TOST_ALERT, CLOSE_TOST_ALERT, OPEN_TOST_CALL_ALERT, CLOSE_TOST_CALL_ALERT, CALL_INITIATED, CALL_INITIATED_WAIT } from '../constant';

const initialState = {
  isModalOpen: false
};

const Tost = {
    isTostOpen:false,
    msg:null,
    TostType:null,
}

const CallTost = {
  isTostOpen:false,
  msg:null,
  TostType:null,
}

const isDisabled = {
    isDisabled:false,
}

export const modalReducer = (state = initialState, action) => {
    // console.log(action.type);
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false
      };
    default:
      return state;
  }
};

export const TostReducer = (state = Tost, action) => {
    // console.log(action.payload);
    switch(action.type){
        case OPEN_TOST_ALERT:
            return {...state, isTostOpen:true, msg:action.payload.msg, TostType:action.payload.Ttype};
        case CLOSE_TOST_ALERT:
            return {...state, isTostOpen:false,msg:null,TostType:null};
        default:
            return state;
    }
}

export const TostCallReducer = (state = CallTost, action) => {
  // console.log(action.payload);
  switch(action.type){
      case OPEN_TOST_CALL_ALERT:
          return {...state, isTostOpen:true, msg:action.payload.msg, TostType:action.payload.Ttype};
      case CLOSE_TOST_CALL_ALERT:
          return {...state, isTostOpen:false,msg:null,TostType:null};
      default:
          return state;
  }
}

export const IsCallInitiatedReducer = (state = isDisabled, action) => {
  switch(action.type){
      case CALL_INITIATED:
          return {...state, isDisabled:action.payload};
      case CALL_INITIATED_WAIT:
          return {...state, isTostOpen:action.payload};
      default:
          return state;
  }
}