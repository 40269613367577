import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
const Buttons = ({title, onclick,color,size,className}) => {
  const Loading = useSelector(state => state.LoadingReducer.isLoading);
    // console.log(Loading);
  return <Button type='submit' className={className} disabled={Loading} variant={color} size={size} onClick={onclick}>
          {!Loading ? <>{title}</> : <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                    }
         </Button>
}

export default Buttons;