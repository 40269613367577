import React, { useEffect } from "react";
import Header from "../Components/header";
import Carousel from "../Components/Carousel/carousel";
import Heading from "../Components/heading/heading";
import Card from "../Components/card/card";
import campaign from '../Assets/images/Group 1392.png';
import '../Style/Home.css';
import Grid from '@mui/material/Grid';
import login from '../Assets/images/Group 1223.png';
import wallet from '../Assets/images/Group 1224.png';
import click from '../Assets/images/Group 1028.png';
import AgentCard from "../Components/card/AgentCard";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import AppBenefits from '../Assets/images/Group 1032.png';
import Obscenity from '../Assets/images/Group 1033.png';
import FakeID from '../Assets/images/Group 1034.png';
import Enjoy from '../Assets/images/Group 1035.png';
import Blocking from '../Assets/images/Group 1225.png';
import ScreenShots from '../Assets/images/Group 1037.png';
import About from "../Components/About/about";
import FAQS from "../Components/FAQs/FAQs";
import Footer from "../Components/Footer/Footer";
import Modal from "../Components/Login/modal";
import { useDispatch, useSelector } from 'react-redux'
import {IslodaingTrueAction } from "../redux/Actions/GetAllonlineAgentsAction";
import SkeletonChildren from "../Components/card/SkeletonCard";
import { InitiateCallAction } from "../redux/Actions/RequestCallAction";
import { openModal, openTostAlert } from "../redux/Actions/modal_action";
import { getAllWalletTransactionAction } from "../redux/Actions/walletTransactionAction";

const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 1, // Number of slides to scroll
    speed: 500,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                initialSlide: 2,
                slidesToScroll: 1,
                centerMode: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "70px",
                rows: 1,
            }
        }
    ]
};



export default function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Agents = useSelector(state => state.GetAllAgentReducer.AllAgents) ?? [];
    const userBalance = useSelector(state => state.walletBalanceReducer.Balance) ?? localStorage.getItem('Balance');
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) ?? '';
    const IsLoading = useSelector(state => state.IslodaingTrueReducer.IsLoading) ?? [];
    const [favourite, setFavourite] = React.useState(JSON.parse(localStorage.getItem('favourite')));
    const isDisabled = useSelector(state => state.IsCallInitiatedReducer.isDisabled);
    const IsUserLogdin = localStorage.getItem('isUserLoggedIn') ?? false;
    const IsFreeSession = localStorage.getItem('IsFreeSession') ?? false;

    // console.log(IsFreeSession);

    const initiateCallRequest = (data) => {
        if(!IsUserLogdin){
            dispatch(openModal());
            dispatch(IslodaingTrueAction(''));
        }else dispatch(InitiateCallAction(data, userBalance, userProfile.phones[0],IsFreeSession,isDisabled)); 
         
    }

    const AddToFavourite =(data)=>{
        const itemIsExist = favourite.some(item => item.pandit.user.id === data.pandit.user.id);
        if(!itemIsExist){
          setFavourite([...favourite, data]);
          localStorage.setItem('favourite',JSON.stringify([...favourite, data]));
         dispatch(openTostAlert('Agent successfully added to the Favourite!','success'));
        } else dispatch(openTostAlert('Agent already exists in the Favourite!','warning'));
    }

    useEffect(() => {
        if(IsUserLogdin) {
            dispatch(getAllWalletTransactionAction(userProfile.wallet_addr))
        }
    },[dispatch]);

    const dd = Agents.filter(item => item.current_activity === null);
    // console.log(dd.length);
    return <>
        <Header />
        <div className="home_carousel_bg">
            <Carousel />
        </div>
        <Modal />
        
        <div className="Let_make_new_people">
            <div className="head_container_in_let_make_new container">
                <div className="heading_in_let_make">
                    <Heading
                        title={"Let's Make New People and Get Entertained"}
                        tagline={'Follow 3 Step To Start Chats and Call'}
                    />
                </div>
                {/* { !IsFreeSession ? <div className="campaign_container text-right">
                    <img src={campaign} alt="campaign" width={'25%'} onClick={() => navigate('/talknow')} />
                </div>:null} */}
            </div>
            <div className="container">
                <div className="Card_container">
                    <Card classN={'border-0 border-right text-center'}
                        img={login}
                        title={<span>Login With <br />Let’s Talk</span>}
                    />
                    <Card classN={'border-0 border-right text-center'}
                        img={wallet}
                        title={<span>Put money in your <br />Let’s Tallk  wallet</span>}
                    />
                    <Card classN={'border-0 border-right nth-child text-center'}
                        img={click}
                        title={<span>Click on to <br /> Call and Chat</span>}
                    />
                </div>
                <div className="Agents_container mt-3">
                    <div className="head_container_in_let_make_new container">
                        <div className="heading_in_let_make">
                            <Heading
                                title={<span>Suggested Profile  <br />Based on Your Interest</span>}
                                tagline={''}
                            />
                        </div>
                    </div>
                    <div className="agents_card_container home_agent_card_container mb-5">
                        <div className="slider-container ">

                            {
                                Object.keys(Agents).length === 0 ? <Grid container spacing={3}>
                                    {Array.from({ length: 4 }, (_, index) => <SkeletonChildren id={index} />)}
                                </Grid>
                                    : <Slider {...settings} rows={dd.length <= 3 ? 1 : 2}>
                                        {
                                            Agents.map(item => {
                                                if(item.current_activity == null){
                                                    return <div className="sliderItem agentsItem">
                                                    <AgentCard data={item}
                                                        id={item.pandit.user.id}
                                                        image={item.pandit.user.avatar}
                                                        name={item.pandit.user.name}
                                                        age={'25'}
                                                        eng={"Hindi,English"}
                                                        description={'Talks about: Cooking, Dating, Friendship, Make-Up, Acting'}
                                                        IsCall={true}
                                                        isLine2={true}
                                                        IslodaingTrue={IsLoading == item.pandit.user.id}
                                                        IsFavourite={true}
                                                        AddToFavourite={AddToFavourite}
                                                        CallinitiateRequest={() => {
                                                            dispatch(IslodaingTrueAction(item.pandit.user.id));
                                                            initiateCallRequest(item);
                                                        }}
                                                    />
                                                </div>
                                                }
                                            }
                                                
                                            )
                                        }
                                    </Slider>
                            }

                        </div>

                    </div>
                </div>

            </div>
            <div className="AppBenefits_container">
                <div className="container">
                    <div className="AppBenefits_Inner_container d-flex align-items-center column-gap-5 w-100">
                        <div className="AppBenefits_content_container">
                            <div className="heading_in_let_make">
                                <Heading
                                    title={<span>Our  <br />Let's Talk App Benefits</span>}
                                    tagline={''}
                                />
                            </div>
                            <ul style={{ listStyle: 'none', textAlign: 'left' }}>
                                <li className=""> <img src={Obscenity} alt="No Obscenity" width={'60px'} /> &nbsp;&nbsp;&nbsp;&nbsp; <strong>No Obscenity</strong></li>
                                <li > <img src={FakeID} alt="No Fake ID<" width={'60px'} /> &nbsp;&nbsp;&nbsp;&nbsp;<strong> No Fake ID</strong></li>
                                <li> <img src={Enjoy} alt=" Enjoy Anonymity" width={'60px'} />&nbsp;&nbsp;&nbsp;&nbsp; <strong>Enjoy Anonymity</strong></li>
                                <li> <img src={Blocking} alt=" Blocking Option" width={'60px'} />&nbsp;&nbsp;&nbsp;&nbsp; <strong>Blocking Option</strong></li>
                                <li> <img src={ScreenShots} alt="No ScreenShots" width={'60px'} />&nbsp;&nbsp;&nbsp;&nbsp; <strong>No ScreenShots</strong></li>
                            </ul>
                        </div>
                        <div className="AppBenefits_img_container text-justify">
                            <img src={AppBenefits} alt="About Banner" className="" width={'100%'} />
                        </div>
                    </div>
                </div>
            </div>

            <About />
            <div className="container faqs_container">
                <div className="mob_center">
                    <div className="heading_in_let_make mb-5">
                        <Heading
                            title={<span>Frequently Asked <br />
                                Questions</span>}
                            tagline={''}
                        />
                    </div>
                </div>
                <div className="mb-5">
                    <FAQS />
                </div>
            </div>
            <div className="mt-5 mob_480">&nbsp;</div>
            <div className="desk_top">
                <Footer />
            </div>

        </div>


    </>
}