import React from "react";
import ConfirmationPopup from "./Confirmation";
import Wait from '../../../Assets/images/deadline 1.png';
import Call from '../../../Assets/images/Group 1227.png';
import IconButton from '@mui/material/IconButton';
import {useDispatch,useSelector} from 'react-redux';
import { InitiateCallAction } from "../../../redux/Actions/RequestCallAction";

const DefaultMsg = ({agentData}) => {
    const dispatch = useDispatch();
    const userBalance = useSelector(state => (state.walletBalanceReducer.Balance)) ?? '';
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    const isDisabled = useSelector(state => state.IsCallInitiatedReducer.isDisabled);
    const IsFreeSession = localStorage.getItem('IsFreeSession') ?? false;
    // console.log(agentData);

    const initiateCallRequest = (data) => { 
        dispatch(InitiateCallAction(data, userBalance, userProfile.phones[0],IsFreeSession,isDisabled));   
    }

    return (
        <div className={"Defaultmsg_container text-center"}>
            <img src={Wait} alt={''} width={"60px"} height={'60px'} />
            <div className='w-100'>
                <p className='mt-2 fw-medium'>Dear User, while the expert isn't available <br />for chat at the moment. Call Instead!</p>
                <IconButton aria-label="call" size="small" onClick={() => initiateCallRequest(agentData)}>
                    <img src={Call} alt='Call btn' width={'80px'} />
                </IconButton>
            </div>
        </div>
    );
};

const Defaultpopup = ({Isopen, handleClose, data}) => {
    return <ConfirmationPopup modalBody={<DefaultMsg agentData={data} />} handleClose={handleClose} Isopen={Isopen} />;
};

export default Defaultpopup;
