import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './card.css';

function Cards({classN,img,title,text, isbutton,btnTitle}) {
  return (
    <Card className={`Card_container1 ${classN}`}>
      <Card.Img variant="top" src={img} width={'100px'} />
      <Card.Body>
        <Card.Title className='title_width'>{title}</Card.Title>
        <Card.Text>
          {text}
        </Card.Text>
        { isbutton ? <Button variant="primary">{btnTitle}</Button>:''}
      </Card.Body>
    </Card>
  );
}

export default Cards;