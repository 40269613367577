import React from "react";
import Header from "../Components/header";
import Footer from "../Components/Footer/Footer";
import PageHeader from "../Components/Header/pageHeader";
import FAQs from "../Components/FAQs/FAQs";
import '../Components/FAQs/FAQs.css';

export default function FAQs1(){
    return<>
        <div className="desk_top">
            <Header />
        </div>
        <PageHeader title={'FAQs'} />
        <div className="container faqs_container faqcontainerpage">
            <FAQs />
        </div>
        <div className="desk_top">
            <Footer />
        </div>
    </>
}