import React, { useState } from 'react';
import ChatWindow from "../Components/chatsys/chat_window";
import { useLocation } from 'react-router-dom';
import IconButton from "@mui/material/IconButton";
import Gift from "../Assets/images/Group 1331.png";
import PageHeader from "../Components/Header/pageHeader";
import '../Style/chatSocket.css';
import GiftPopup from "../Components/chatsys/chat_window/gifts";
function WebSocketDemo(props) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [giftshow, setGiftshow] = useState(false);
  // console.log(location);

  return (
    <>
    <div className='mob_480 w-100'>
    <div className="chat-window-container_header d-flex ">
        <div className="chat-window-container_header-left">
          <PageHeader title={`Chat with ${location.state.orderDetail.pandit.user.name}`} />
        </div>
        <div className="chat-window-container_header-right d-flex align-items-center justify-content-end">
            <img src={Gift} onClick={() => setGiftshow(true)} alt="gift" width={"30px"} />
        </div>
        </div>
      </div>
      <div className='container'>
        <div className="mb-5 chat_window_msg_container">
          <div className="chat_socket_body d-flex justify-content-between" style={{ marginLeft: '-17px' }}>
            <div className={"bg-chat d-flex align-items-start justify-content-center w-100"}>
              <div className="chatinner_container1 mt-1 p-5 position-relative w-100">
                <ChatWindow
                  isOpen={isOpen}
                  props={props}
                />
              </div>

            </div>
          </div>
        </div>
        <GiftPopup
          title={"Virtual Gift With Talknow"}
          props={props}
          AgentId={location.state.orderDetail.pandit.user.id}
          show={giftshow}
          Handleclose={() => setGiftshow(false)}
        />


      </div>
    </>
  );
};

export default WebSocketDemo; 