import {ADD_TO_FAVOURITE, ALL_AGENTS_, ALL_AGENTS_FAILURE, ALL_AGENTS_START, GET_AGENTS_CHAT_HISTORY, GET_AGENTS_CHAT_HISTORY_FAILURE, GET_AGENTS_CHAT_HISTORY_START, GET_AGENTS_CHAT_MSG, GET_AGENTS_CHAT_MSG_FAILURE, GET_AGENTS_CHAT_MSG_START, GET_ALL_ONLINE_AGENTS, GET_ALL_ONLINE_AGENTS_FAILURE, GET_ALL_ONLINE_AGENTS_START, ISLOADINGTRUE, ISLOADINGTRUE_FAILURE,ADD_TO_CART} from '../constant';

const initialState = {AllAgents:null}
const loadingState = {IsLoading:null}
const CallHistoryState = {callHistory:null}
const ChatHistoryState = {chatHistory:null,Agents:null}
const ChatMsgState = {chatMsg:null};
const initialState2 = {
    favourite:[],
  };
export const GetAllAgentReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_ALL_ONLINE_AGENTS_START:
            return {AllAgents:null};

        case GET_ALL_ONLINE_AGENTS:
            return {...state, AllAgents: action.payload};

        case GET_ALL_ONLINE_AGENTS_FAILURE:
            return {AllAgents:null};
        default:
            return state;

    }
}

export const IslodaingTrueReducer = (state = loadingState, action) => {
    switch(action.type){
        case ISLOADINGTRUE:
            return{...state, IsLoading:action.payload};
        case ISLOADINGTRUE_FAILURE:
            return {...state, IsLoading:null}
        default:
            return state;
    }
}

export const GetCallHistoryReducer = (state = CallHistoryState, action) => {
    // console.log(action);
    switch(action.type){
        case ALL_AGENTS_START:
            return{...state, callHistory:null};
        case ALL_AGENTS_:
            return {...state, callHistory:action.payload};
        case ALL_AGENTS_FAILURE:
            return{...state, callHistory:null};
        default:
            return state;
    }
}

export const GetChatHistoryReducer = (state = ChatHistoryState, action) => {
    // console.log(action);
    switch(action.type){
        case GET_AGENTS_CHAT_HISTORY_START:
            return{...state, chatHistory:null,Agents:null};
        case GET_AGENTS_CHAT_HISTORY:
            return {...state, chatHistory:action.payload.chatHistory};
        case GET_AGENTS_CHAT_HISTORY_FAILURE:
            return{...state, chatHistory:null,Agents:null};
        default:
            return state;
    }
}

export const GetChatMsgReducer = (state = ChatMsgState, action) => {
    switch(action.type){
        case GET_AGENTS_CHAT_MSG_START:
            return{...state, chatMsg:null};
        case GET_AGENTS_CHAT_MSG:
            return {...state, chatMsg:action.payload};
        case GET_AGENTS_CHAT_MSG_FAILURE:
            return{...state, chatMsg:null};
        default:
            return state;
    }
}

export const cartReducer = (state = initialState2, action) => {
    switch (action.type) {
      case ADD_TO_CART:
          // Item doesn't exist, add to cart
          return {
            ...state,
            favourite: [...state.favourite, action.payload],
          };
      default:
        return state;
    }
  };


// export const AddToFavouriteReducer = (state = initialState2, action) => {
//     switch(action.type){
//         case ADD_TO_FAVOURITE:
          
//     }
// }