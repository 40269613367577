import * as Constent from '../constant';

const initialState = {
    Balance: null,
}

export const walletBalanceReducer = (state = initialState, action) => {
    // console.log(action.payload);
    switch (action.type) {
      case Constent.FETCH_WALLET_BALANCE_START:
        return { ...state, loading: true, error: null };
      case Constent.FETCH_WALLET_BALANCE:
        return { ...state, Balance:action.payload, loading: false };
      case Constent.FETCH_WALLET_BALANCE_FAILURE:
        return { ...state, loading: false, error: 'Failed to fetch user balance' };
      default:
        return state;
    }
  };