import axios from 'axios';
import API_urls from '../../configuration/API_urls';
import { GET_ALL_WALLET_TRANSACTION_START, GET_ALL_WALLET_TRANSACTION_FAILURE, GET_ALL_WALLET_TRANSACTION } from '../constant';
import { openTostAlert } from './modal_action';

function sortByDateDesc (array, dateField){
    return array.sort((a, b) => b[dateField] - a[dateField]);
}

export const getAllWalletTransactionAction = (userId) => async (dispatch) => {
    dispatch({ type: GET_ALL_WALLET_TRANSACTION_START });
    try {
        var url = API_urls.Wallet_transactions;
        const body = { id: userId };
        var res = await axios.post(url, body);
        if(res.data.wallet.length === 0 && res.data.cash.length === 0){
            localStorage.setItem('IsFreeSession', true);
        }else localStorage.setItem('IsFreeSession', false);
        // console.log(res.data.wallet.length);
        res.data.wallet = sortByDateDesc(res.data.wallet, 'start_at');
        res.data.cash = sortByDateDesc(res.data.cash, 'created_at');

        dispatch({ type: GET_ALL_WALLET_TRANSACTION, payload: { wallet: res.data.wallet, cash: res.data.cash } });

    } catch (err) {
        console.log(err);
        dispatch({ type: GET_ALL_WALLET_TRANSACTION_FAILURE });
        dispatch(openTostAlert(`There was a problem fetching wallet transaction data:${err.message}`, 'error'));
    }
}

