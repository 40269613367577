import React from 'react';
import Header from '../Components/header';
import Footer from '../Components/Footer/Footer';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import PageHeader from '../Components/Header/pageHeader';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { Divider } from '@mui/material';
import {useDispatch} from 'react-redux';
import { openTostAlert } from '../redux/Actions/modal_action';

 function CustomDeleteIconChips({chipData, handleClick }) {
    return (
      <Box sx={{display:'flex',flexWrap:'wrap',gap:'10px',alignItems:'center',justifyContent:'center',marginTop:'30px'}}>
        {chipData.map((data,i) => {
            return  <Chip
            key={data.key}
            label={data.label}
            className={data.status === 'added' ? "langActive" : data.status === 'removed' ? null : null}
            onDelete={() => handleClick(data,i)}
            deleteIcon={ data.status === 'added' ? <CancelIcon /> : <AddIcon />}
          />
        })}
      </Box>
    );
  }


export default function Preferences(props) {
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        gander: '',
        language: [],
        age: '',
    });

    const [chipData, setChipData] = React.useState([
        { key: 0, label: 'English',status:'' },
        { key: 1, label: 'Hindi',status:'' },
        { key: 2, label: 'Punjabi',status:'' },
        { key: 3, label: 'Gujrati',status:'' },
        { key: 4, label: 'Marathi',status:'' }, 
        { key: 5, label: 'Gujrati',status:'' }, 
      ]);


    const handleClick = (newItem) => {
       
        const existItem = state.language.some((item) => item.key === newItem.key);
        console.log(existItem);
        if (!existItem) {
            setState({ ...state, language:[...state.language, newItem.label]});
            // setSelectLanguage([...selectLanguage, newItem]);
            setChipData((prevData)=> prevData.map((item)=> item.key === newItem.key ? {...item, status:'added'} : item));
            
        }else {
            setState({ ...state, language: (chips) => chips.filter((chip) => chip.key !== newItem.key) });
            setChipData((prevData)=> prevData.map((item)=> item.key === newItem.key ? {...item, status:'removed'} : item));
        };
    };
    const handleChange = (e) => {
        setState({ ...state, gander: e.target.value });
    };


  const OnSubmitHandle = () => {
    if(state.gander === '' || state.language.length === 0 || state.age === ''){
        dispatch(openTostAlert('Please fill all fields','error'));
    }else {dispatch(openTostAlert('Preferences Updated Successfully','success'));}
  }
    return <>

        <div className='desk_top'>
            <Header />
        </div>
        <PageHeader title={'Preferences'} />
        <div className='conatiner'>
            <div className='Preferences_container d-flex align-items-center justify-content-center'>
                <form className='Preferences_form_container'>
                    <div className='Preferences_heading'>
                        <h5>Choose Your Partner as Per Your Interest</h5>
                    </div>
                    <div className='Genders_container mt-5'>
                        <div className='radio-form d-flex align-items-center flex-wrap'>
                            <div className='radio-group'>
                                <input type="radio" id="Men" onChange={handleChange} name="fav_language" value="Men" />
                                <label for="Men">Men</label>
                            </div>
                            <div className='radio-group'>
                                <input type="radio" id="Women" onChange={handleChange} name="fav_language" value="Women" />
                                <label for="Women">Women</label>
                            </div>
                            <div className='radio-group'>
                                <input type="radio" id="Transmen" onChange={handleChange} name="fav_language" value="Transmen" />
                                <label for="Transmen">Transmen</label>
                            </div>
                            <div className='radio-group'>
                                <input type="radio" id="Transwomen" onChange={handleChange} name="fav_language" value="Transwomen" />
                                <label for="Transwomen">Transwomen</label>
                            </div>
                            <div className='radio-group'>
                                <input type="radio" id="Gay" onChange={handleChange} name="fav_language" value="Gay" />
                                <label for="Gay">Gay</label>
                            </div>
                            <div className='radio-group'>
                                <input type="radio" id="Lesbian" onChange={handleChange} name="fav_language" value="Lesbian" />
                                <label for="Lesbian">Lesbian</label>
                            </div>



                        </div>
                    </div>
                    <div className='language_container1'>
                        <Divider />
                        <h5 className='mt-2 mb-2'>Language</h5>
                        <Divider />
                        <CustomDeleteIconChips chipData={chipData} handleClick={handleClick} />
                    </div>
                    <div className='language_container Age-group_container mt-5'>
                        <h5>Age Group</h5>
                        <Box sx={{ width: '100%', padding: '0px 70px', marginTop: '30px' }}>
                            <p className='m-0 text-right'>18-100</p>
                            <Slider
                                size="small"
                                defaultValue={18}
                                onChange={(e) =>  setState({...state, age:e.target.value})}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                            />
                        </Box>
                    </div>
                    <div className='language_container  mt-5'>
                        <Button variant="contained" onClick={OnSubmitHandle}>Submit</Button>
                    </div>
                </form>
            </div>
        </div>
        <div className="mt-5 mob_480">&nbsp;</div>
        <div className='desk_top'>
            <Footer />
        </div>
        
    </>
}