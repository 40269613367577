import { combineReducers, createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { modalReducer, TostReducer,TostCallReducer,IsCallInitiatedReducer } from './Reducers/modal_reducer';
import {
    FatchUserReducer,
    loginReducer, veriftOTPReducer,LoadingReducer
} from './Reducers/login_reducer';
import { walletBalanceReducer } from './Reducers/walletBalanceReducer';
import { GetAllAgentReducer, IslodaingTrueReducer, streamReducer,
    GetCallHistoryReducer, GetChatHistoryReducer,GetChatMsgReducer,cartReducer} from './Reducers/GetAllAgentsReducer';
import { WalletTransactionReducer } from './Reducers/WalletTransactionReducer';
import { walletRechargeReducer } from './Reducers/walletRechargeReducer';

// Combine multiple reducers into one
const rootReducer = combineReducers({
    modalReducer,
    TostReducer,
    cartReducer,
    TostCallReducer,
    FatchUserReducer,
    loginReducer,
    LoadingReducer,
    veriftOTPReducer,
    walletBalanceReducer,
    GetAllAgentReducer,
    WalletTransactionReducer,
    IslodaingTrueReducer,
    GetCallHistoryReducer,
    GetChatHistoryReducer,
    GetChatMsgReducer,
    walletRechargeReducer,
    IsCallInitiatedReducer
})

// Create Redux store with combined reducers
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
